<template>
  <div>
    <vue-bottom-sheet
      ref="compareVersion"
      :click-to-close="false"
      block
      max-width="90%"
    >
      <div
        class="bottom-sheet"
        style="min-height: 90vh"
      >
        <div class="d-flex justify-content-between mb-1 align-content-center">
          <div>
            <h4>Lihat Versi</h4>
            <h5>Quote : <span class="text-primary">{{ quoteData.name }}</span></h5>
          </div>
          <feather-icon
            icon="XIcon"
            size="28"
            class="text-primary"
            @click="$refs.compareVersion.close()"
          />
        </div>
        <v-select
          v-model="selectedVersion"
          class="w-100 shadow-sm mb-1"
          :options="allVersion"
          placeholder="Pilih versi..."
          multiple
          @option:selected="handleVersionSelected"
          @option:deselected="handleVersionDeselected"
        />
        <b-overlay :show="loadingVersion">
          <swiper
            class="swiper-multiple mb-5 w-100"
            :options="swiperOptions"
          >
            <swiper-slide>
              <b-card
                class="w-100 w-md-50"
                border-variant="primary"
              >
                <div class="d-flex justify-content-between">
                  <div>
                    <h5 class="text-primary m-0">
                      Versi {{ lastVersion }}

                      <b-badge
                        class="ml-25"
                        variant="light-primary"
                      >
                        Terbaru
                      </b-badge>
                    </h5>
                    <small class="text-secondary">
                      Dibuat tanggal : {{ formatDate(quoteData.created_at) }}
                    </small>
                    <small
                      v-if="quoteData.valid_until"
                      class="text-secondary d-block"
                    >
                      Berlaku Sampai : {{ formatDate(quoteData.valid_until) }}
                    </small>
                  </div>
                </div>
                <div
                  v-for="(area, indexArea) in quoteData.areaItems"
                  :key="indexArea"
                >
                  <div class="d-flex justify-content-between align-items-end">
                    <div>
                      <h5 class="text-primary m-0 mt-1">
                        {{ area.category.name }}
                      </h5>
                      <small class="text-muted">
                        Luas Area : {{ area.surface_area ? area.surface_area : 0 }} M2
                      </small>
                    </div>
                    <small class="text-muted">
                      Loss Factor : {{ area.items.length && area.items[0].loss !== null ? `${area.items[0].loss} %` : 0 }}
                    </small>
                  </div>
                  <div
                    v-for="(item, indexItem) in area.items"
                    :key="indexItem"
                    class="d-flex justify-content-between align-items-end"
                  >
                    <div>
                      <h6 class="m-0 mt-50">
                        {{ item.item_name }}
                      </h6>
                      <small class="text-muted">
                        {{ item.item_code }} • {{ item.item_type.name }}
                      </small>
                    </div>
                    <div class="d-flex flex-column justify-content-end align-items-end">
                      <small class="text-muted">
                        {{ item.liter }} liter x {{ item.price }}
                      </small>
                      <small class="font-weight-bold">
                        {{ formatCurrency(item.subtotal) }}
                      </small>
                    </div>
                  </div>
                  <div class="d-flex justify-content-between mt-50">
                    <small class="text-muted">
                      Total Area
                    </small>
                    <h6 class="text-right">
                      {{ formatCurrency(area.total) }}
                    </h6>
                  </div>
                </div>
                <hr>
                <div class="d-flex justify-content-between">
                  <small class="text-muted">
                    Diskon
                  </small>
                  <h6 class="text-primary font-wight-bolder">
                    {{ formatCurrency(quoteData.discount) }}
                  </h6>
                </div>
                <div class="d-flex justify-content-between">
                  <small class="text-muted">
                    Total
                  </small>
                  <h5 class="text-primary font-wight-bolder">
                    {{ formatCurrency(quoteData.total) }}
                  </h5>
                </div>
              </b-card>
            </swiper-slide>
            <swiper-slide
              v-for="(data, indexVersion) in listVersion"
              :key="indexVersion"
            >
              <b-card
                class="w-100 w-md-50"
                border-variant="light"
              >
                <div class="d-flex justify-content-between">
                  <div>
                    <h5 class="text-primary m-0">
                      {{ data.label }}
                    </h5>
                    <small class="text-secondary">
                      Dibuat tanggal : {{ formatDate(data.created_at) }}
                    </small>
                    <small
                      v-if="quoteData.valid_until"
                      class="text-secondary d-block"
                    >
                      Berlaku Sampai : {{ formatDate(data.valid_until) }}
                    </small>
                  </div>
                </div>
                <div
                  v-for="(area, indexArea) in data.areaItems"
                  :key="indexArea"
                  :set="compareArea = data.comparison.area[indexArea]"
                  :class="{ 'different' : data.comparison.area[indexArea].deleted_area }"
                >
                  <div class="d-flex justify-content-between align-items-end">
                    <div>
                      <h5 class="text-primary m-0 mt-1">
                        {{ area.category.name }}
                      </h5>
                      <small
                        class="text-muted"
                        :class="{ 'different' : compareArea.luas }"
                      >
                        Luas Area : {{ area.surface_area ? area.surface_area : 0 }} M2
                      </small>
                    </div>
                    <small
                      class="text-muted"
                      :class="{ 'different' : compareArea.loss }"
                    >
                      Loss Factor : {{ area.items.length && area.items[0].loss !== null ? `${area.items[0].loss} %` : 0 }}
                    </small>
                  </div>
                  <div
                    v-for="(item, indexItem) in area.items"
                    :key="indexItem"
                    class="d-flex justify-content-between align-items-end"
                    :set="compareItem = compareArea.items[indexItem]"
                    :class="{ 'deleted' : compareArea.items[indexItem].deleted_item }"
                  >
                    <div>
                      <h6 class="m-0 mt-50">
                        {{ item.item_name }}
                      </h6>
                      <small class="text-muted">
                        {{ item.item_code }} • {{ item.item_type.name }}
                      </small>
                    </div>
                    <div class="d-flex flex-column justify-content-end align-items-end">
                      <small class="text-muted">
                        <span :class="{ 'different' :compareItem.liter }">
                          {{ item.liter }} liter
                        </span>
                        x
                        <span :class="{ 'different' :compareItem.price }">
                          {{ item.price }}
                        </span>
                      </small>
                      <small
                        class="font-weight-bold"
                        :class="{ 'different' :compareItem.subtotal }"
                      >
                        {{ formatCurrency(item.subtotal) }}
                      </small>
                    </div>
                  </div>
                  <div class="d-flex justify-content-between mt-50">
                    <small class="text-muted">
                      Total Area
                    </small>
                    <h6
                      class="text-right"
                      :class="{ 'different' :compareArea.total }"
                    >
                      {{ formatCurrency(area.total) }}
                    </h6>
                  </div>
                </div>
                <hr>
                <div class="d-flex justify-content-between">
                  <small class="text-muted">
                    Diskon
                  </small>
                  <h6
                    class="text-primary font-wight-bolder"
                    :class="{ 'different' : data.comparison.discount }"
                  >
                    {{ formatCurrency(data.discount) }}
                  </h6>
                </div>
                <div class="d-flex justify-content-between">
                  <small class="text-muted">
                    Total
                  </small>
                  <h5
                    class="text-primary font-wight-bolder"
                    :class="{ 'different' : data.comparison.total }"
                  >
                    {{ formatCurrency(data.total) }}
                  </h5>
                </div>
              </b-card>
            </swiper-slide>
            <div
              slot="pagination"
              class="swiper-pagination pt-1"
            />
          </swiper>
        </b-overlay>
        <div
          v-if="listVersion.length"
          class="d-flex align-items-center justify-content-center"
        >
          <div class="d-flex align-items-center mr-2">
            <div class="p-75 deleted mr-50" />
            <small class="text-secondary">Dihapus</small>
          </div>
          <div class="d-flex align-items-center">
            <div class="p-75 different mr-50" />
            <small class="text-secondary">Diubah</small>
          </div>
        </div>
      </div>
    </vue-bottom-sheet>
  </div>
</template>

<script>
import { BCard, BOverlay, BBadge } from 'bootstrap-vue'
import vSelect from 'vue-select'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import VueBottomSheet from '@webzlodimir/vue-bottom-sheet'
import { formatDate, formatCurrency } from '@core/utils/filter'
import { mapGetters } from 'vuex'

export default {
  components: {
    BCard,
    BOverlay,
    BBadge,
    vSelect,
    Swiper,
    SwiperSlide,
    VueBottomSheet,
  },
  props: {
    lastVersion: {
      type: Number,
      required: true,
      default: 1,
    },
    id: {
      type: Number,
      required: true,
      default: 0,
    },
    quoteData: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      selectedVersion: [],
      listVersion: [],
      loadingVersion: false,
      swiperOptions: {
        slidesPerView: 1,
        spaceBetween: 10,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        breakpoints: {
          860: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
          320: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
        },
      },
    }
  },
  computed: {
    allVersion() {
      return [...Array(this.lastVersion - 1).keys()].map(x => ({
        label: `Versi ${x + 1}`,
        value: x + 1,
      })).filter(el => el.value !== Number(this.version))
    },
    ...mapGetters({
      permission: 'getUserPermission',
    }),
  },
  methods: {
    formatDate,
    formatCurrency,
    open() {
      this.$refs.compareVersion.open()
    },
    handleVersionSelected(item) {
      this.loadingVersion = true
      if (this.listVersion.length > 0) {
        item.forEach((el, index) => {
          if (!this.listVersion.some(version => version.label === el.label)) {
            this.$store.dispatch('getQuoteDetail', { quote_id: this.id, version: el.value }).then(async data => {
              const comparison = await this.compareCheck(data)
              this.loadingVersion = false
              this.listVersion[index] = {
                ...data,
                label: el.label,
                comparison,
              }
            }).catch(() => {
              this.loadingVersion = false
            })
          }
        })
      } else {
        item.forEach((el, index) => {
          this.$store.dispatch('getQuoteDetail', { quote_id: this.id, version: el.value }).then(async data => {
            const comparison = await this.compareCheck(data)
            this.loadingVersion = false
            this.listVersion[index] = {
              ...data,
              label: el.label,
              comparison,
            }
          }).catch(() => {
            this.loadingVersion = false
          })
        })
      }
    },
    compareCheck(data) {
      return new Promise(resolve => {
        const diff = {}

        // compare total & diskon
        if (this.quoteData.total !== data.total) {
          diff.total = true
        }

        if (this.quoteData.discount !== data.discount) {
          diff.discount = true
        }

        // compare area (luas, loss, total, jumlah item, new item)
        data.areaItems.forEach(area => {
          const mainArea = this.quoteData.areaItems.filter(el => el.category.id === area.category.id)
          const diffArea = {
            area_id: area.category.id,
          }

          if (mainArea.length) {
            if (mainArea[0].surface_area !== area.surface_area) {
              diffArea.luas = true
            } else {
              diffArea.luas = false
            }

            if (mainArea[0].total !== area.total) {
              diffArea.total = true
            } else {
              diffArea.total = false
            }

            if (mainArea[0].items.length !== area.items.length) {
              diffArea.item_qty = true
            } else {
              diffArea.item_qty = false
            }

            // compare item (liter, harga, hpp, total, new item)
            area.items.forEach(item => {
              const mainItem = mainArea[0].items.filter(el => el.item_id === item.item_id)
              const diffItem = {
                item_id: item.item_id,
              }

              if (mainItem.length) {
                diffItem.deleted_item = false

                if (mainArea[0].items[0].loss && area.items[0].loss && mainArea[0].items[0].loss !== area.items[0].loss) {
                  diffArea.loss = true
                } else {
                  diffArea.loss = false
                }

                if (mainItem[0].liter !== item.liter) {
                  diffItem.liter = true
                } else {
                  diffItem.liter = false
                }

                if (mainItem[0].price !== item.price) {
                  diffItem.price = true
                } else {
                  diffItem.price = false
                }

                if (mainItem[0].hpp !== item.hpp) {
                  diffItem.hpp = true
                } else {
                  diffItem.hpp = false
                }

                if (mainItem[0].subtotal !== item.subtotal) {
                  diffItem.subtotal = true
                } else {
                  diffItem.subtotal = false
                }

                if (mainItem[0].subtotal_hpp !== item.subtotal_hpp) {
                  diffItem.subtotal_hpp = true
                } else {
                  diffItem.subtotal_hpp = false
                }
              } else {
                diffItem.deleted_item = true
              }

              // eslint-disable-next-line no-prototype-builtins
              if (diffArea.hasOwnProperty('items')) {
                diffArea.items.push(diffItem)
              } else {
                diffArea.items = [diffItem]
              }
            })
          } else {
            diffArea.deleted_area = true
          }

          // eslint-disable-next-line no-prototype-builtins
          if (diff.hasOwnProperty('area')) {
            diff.area.push(diffArea)
          } else {
            diff.area = [diffArea]
          }
        })

        resolve(diff)
      })
    },
    handleVersionDeselected(data) {
      this.loadingVersion = true
      this.listVersion = this.listVersion.filter(el => el.label !== data.label)
      this.loadingVersion = false
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/swiper.scss';

.bottom-sheet {
  margin: 16px;
}

.deleted {
  background-color: rgb(254, 0, 0, 0.3) !important;
}

.different {
  background-color: rgba(254, 182, 0, 0.3) !important;
}
</style>
