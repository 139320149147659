const captionSetLiter = (packaging, agent1, agent2) => {
  if (agent1 && agent2) {
    const agentPackaging1 = agent1.packagings.find(el => el.packaging_name === packaging.packaging_name)
    const agentPackaging2 = agent2.packagings.find(el => el.packaging_name === packaging.packaging_name)

    return `(${packaging.liter} liter + ${agentPackaging1.liter} liter + ${agentPackaging2.liter} liter)`
  }

  if (agent1) {
    const agentPackaging1 = agent1.packagings.find(el => el.packaging_name === packaging.packaging_name)
    return `(${packaging.liter} liter + ${agentPackaging1.liter} liter)`
  }

  return `(${packaging.liter} liter)`
}

/* eslint-disable import/prefer-default-export */
export {
  captionSetLiter,
}
