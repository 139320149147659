<template>
  <b-modal
    id="reminder-modal"
    ref="reminderModal"
    hide-header
    hide-footer
    hide-header-close
    centered
  >
    <div class="d-block text-center mt-1">
      <h3>Update Project Reminder</h3>
      <h6 class="mt-50">
        Pilih tanggal reminder selanjutnya dari project ini.
        <b-form-datepicker
          v-model="nextReminder"
          button-only
          right
          locale="id-ID"
          aria-controls="example-input"
          button-variant="outline-primary"
          class="custom-date-picker"
          :min="new Date()"
        >
          <template #button-content>
            <span class="text-primary cursor-pointer mr-50">Pilih tanggal <feather-icon
              size="14"
              icon="CalendarIcon"
            /></span>
          </template>
        </b-form-datepicker>
      </h6>
    </div>
    <div class="d-flex justify-content-center mt-2 mb-1">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        class="mr-1"
        @click="updateProjectReminder()"
      >
        Update
      </b-button>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="outline-danger"
        @click="$bvModal.hide('reminder-modal')"
      >
        Tidak Perlu
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import { BFormDatepicker, BButton } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BFormDatepicker,
    BButton,
  },
  data() {
    return {
      id: null,
      projectData: {},
      nextReminder: new Date(),
    }
  },
  methods: {
    show(id, projectData) {
      this.id = id
      this.projectData = projectData
      this.$refs.reminderModal.show()
    },
    updateProjectReminder() {
      this.$refs.reminderModal.hide()
      this.$store.dispatch('updateProjectReminder', {
        project_id: this.id,
        tgl_reminder: new Date(Date.parse(this.nextReminder)),
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Berhasil mengupdate reminder project!',
            icon: 'EditIcon',
            variant: 'success',
          },
        })
        this.$refs.reminderModal.hide()
      })
    },
  },
}
</script>

<style>
.custom-date-picker .btn {
  padding: 0;
  border: 0 !important;
}
</style>
