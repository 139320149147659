<template>
  <div>
    <div
      v-if="loading !== 'error'"
      class="invoice-add-wrapper"
    >
      <back-navigation title="Detail Quote" />
      <div
        v-if="loading"
        key="1"
        class="d-flex align-items-center justify-content-center"
        style="height: 80vh"
      >
        <b-spinner variant="primary" />
      </div>
      <div
        v-else
        key="2"
      >
        <b-alert
          variant="warning"
          :show="version < quotationData.last_version"
        >
          <div class="alert-body">
            Kamu melihat quote versi lama.
            <b-link
              class="alert-link"
              @click="$router.push({ name: 'quotation-detail', params: {id: id, version: quotationData.last_version.toString()} })"
            >
              Klik disini untuk melihat versi terbaru
              <feather-icon
                icon="ArrowRightIcon"
                class="mt-25"
              />
            </b-link>
          </div>
        </b-alert>
        <b-row class="invoice-add">
          <b-col
            cols="12"
            xl="8"
          >
            <XyzTransition
              appear
              xyz="fade left-3"
            >
              <b-card
                no-body
                class="invoice-preview-card w-100"
              >
                <XyzTransitionGroup
                  appear
                  class="item-group"
                  xyz="fade stagger-1 left-3 duration-6 delay-3"
                >
                  <b-card-body key="1">
                    <div class="d-flex justify-content-between flex-md-row flex-column mt-0">
                      <div class="mr-2">
                        <div
                          v-if="quotationData.reference_db.id !== null"
                        >
                          <b-img
                            height="30"
                            :src="quotationData.reference_db.id === 0 ? `${$restHTTP}/assets/img/reference_db/0_long.png` : `${$restHTTP}/assets/img/reference_db/1_long.png`"
                            class="mb-50"
                          />
                        </div>
                        <h3
                          v-else
                          class="font-weight-bolder text-primary"
                        >
                          Quotation
                        </h3>
                        <b-card
                          v-if="quotationData.notes"
                          border-variant="light"
                          class="p-1 my-1"
                          no-body
                        >
                          <small>Notes :</small>
                          <p
                            style="white-space: pre-line"
                            class="m-0"
                          >
                            {{ quotationData.notes }}
                          </p>
                        </b-card>
                        <div class="d-flex align-items-center mb-50">
                          <span class="mr-1">
                            Tanggal:
                          </span>
                          <h6 class="m-0">
                            {{ formatDate(quotationData.created_at) }}
                          </h6>
                        </div>
                        <div class="d-flex align-items-center">
                          <span class="mr-1">
                            Berlaku sampai:
                          </span>
                          <h6 class="m-0">
                            {{ formatDate(quotationData.valid_until) }}
                          </h6>
                        </div>
                      </div>
                      <div class="invoice-number-date mt-md-0 mt-2">
                        <div class="d-flex align-items-center justify-content-md-end">
                          <h4 class="text-muted">
                            {{ quotationData.name }}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </b-card-body>
                  <hr
                    key="12"
                    class="m-0"
                  >
                  <b-card-body key="2">
                    <b-row v-if="quotationData.project">
                      <b-col
                        cols="12"
                        xl="6"
                      >
                        <h6 class="font-weight-normal">
                          Customer :
                        </h6>
                        <router-link :to="{ name: 'customer-detail', params: {id: quotationData.project.customer.id.toString()} }">
                          <h6 class="text-primary m-0 mb-25">
                            {{ quotationData.project.customer.name }}
                          </h6>
                        </router-link>
                        <small class="text-muted d-block">
                          {{ quotationData.project.customer.customerNo }}
                        </small>
                        <b-badge
                          v-if="quotationData.project.customer.category"
                          variant="light-primary"
                          class="mt-50 mb-25"
                        >
                          {{ quotationData.project.customer.category.name }}
                        </b-badge>
                        <div v-if="quotationData.project.customer.customerLimitAmountValue">
                          <div class="d-flex justify-content-between">
                            <small
                              v-b-popover.hover.top="`Sisa piutang : ${formatCurrency(quotationData.project.customer.saldoPiutang)}`"
                              class="m-0"
                            >
                              {{ shortNumber(quotationData.project.customer.saldoPiutang) }}
                            </small>
                            <small
                              v-b-popover.hover.top="`Limit piutang : ${formatCurrency(quotationData.project.customer.customerLimitAmountValue)}`"
                              class="m-0"
                            >
                              {{ shortNumber(quotationData.project.customer.customerLimitAmountValue) }}
                            </small>
                          </div>
                          <b-progress
                            class="mt-50"
                            :value="quotationData.project.customer.saldoPiutang"
                            :max="quotationData.project.customer.customerLimitAmountValue"
                          />
                        </div>
                        <div v-else>
                          <div class="d-flex justify-content-between">
                            <small>Balance :</small>
                            <small>{{ shortNumber(quotationData.project.customer.saldoPiutang) }}</small>
                          </div>
                          <b-progress
                            v-b-popover.hover.top="'Customer ini tidak punya limit, sehingga Sisa piutang tidak terbatas.'"
                            class="mt-50"
                            :value="100"
                            :max="100"
                          />
                        </div>
                      </b-col>
                      <b-col
                        cols="12"
                        xl="6"
                        class="mt-1 mt-xl-0"
                      >
                        <h6 class="font-weight-normal">
                          Project :
                        </h6>
                        <router-link :to="{ name: 'project-detail', params: {id: quotationData.project.id.toString()} }">
                          <h6 class="text-primary m-0">
                            {{ quotationData.project.name }}
                          </h6>
                        </router-link>
                        <b-badge
                          v-if="quotationData.project.category"
                          variant="light-info"
                          class="mt-50 mb-25"
                        >
                          {{ quotationData.project.category.name }}
                        </b-badge>
                        <small
                          v-if="quotationData.project.tgl_reminder"
                          class="d-block"
                        >Tanggal reminder : {{ formatDate(quotationData.project.tgl_reminder) }}</small>
                      </b-col>
                    </b-row>
                  </b-card-body>
                  <hr
                    key="23"
                    class="m-0"
                  >
                  <b-card-body key="3">
                    <div class="d-flex flex-wrap justify-content-between align-items-center">
                      <h5 class="m-0">
                        Detail Area dan Item
                      </h5>
                      <b-badge
                        v-if="dryReport"
                        variant="light-primary"
                        class="p-50 cursor-pointer"
                        @click="$bvModal.show('itemRealisation')"
                      >
                        Realisasi Item (Dry Report)
                      </b-badge>
                    </div>
                    <b-modal
                      v-if="dryReport"
                      id="itemRealisation"
                      ref="itemRealisation"
                      title="Realisasi Item"
                      hide-footer
                      centered
                    >
                      <div class="d-flex flex-wrap justify-content-between">
                        <h6 class="text-muted m-0">
                          Dry Report ID : {{ dryReport.id }}
                        </h6>
                        <small
                          v-if="dryReport.file"
                          class="text-primary cursor-pointer"
                          @click="$refs.pdf.show(dryReport.file)"
                        >
                          Lihat File Dry Report
                        </small>
                      </div>
                      <hr>
                      <div class="horizontal-scroll">
                        <table>
                          <tr>
                            <th class="pb-1 text-muted font-weight-normal">
                              Item
                            </th>
                            <th class="pr-2 pb-1 text-muted font-weight-normal text-right">
                              Quote Data
                            </th>
                            <th class="pb-1 text-muted font-weight-normal text-right">
                              Real Data
                            </th>
                          </tr>
                          <tr
                            v-for="item in dryReport.items"
                            :key="item.item_id"
                          >
                            <td class="pr-4 pb-1">
                              <h6 class="m-0">
                                {{ item.item_name }}
                              </h6>
                              <small class="text-muted">
                                {{ item.item_code }}
                              </small>
                            </td>
                            <td class="pr-2 pb-1 text-right align-top">
                              <h6 class="m-0">
                                {{ item.quote_data }} L
                              </h6>
                            </td>
                            <td class="pb-1 text-right align-top">
                              <h6 class="m-0 text-primary">
                                {{ item.real_data }} L
                              </h6>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </b-modal>
                    <div
                      v-for="(area, indexArea) in quotationData.areaItems"
                      :key="indexArea"
                    >
                      <b-card
                        border-variant="light"
                        class="custom-shadow mt-2"
                      >
                        <div
                          class="d-flex align-items-center"
                          :class="{ 'mb-1' : !area.surface_preparation }"
                        >
                          <div>
                            <small class="text-muted">Area {{ indexArea + 1 }}</small>
                            <h5>
                              {{ area.category.name }}
                            </h5>
                          </div>
                          <div
                            v-if="area.surface_area"
                            class="ml-auto"
                          >
                            <small class="text-muted">
                              Luas Area
                            </small>
                            <h6 class="m-0 text-right">
                              {{ area.surface_area }} M2
                            </h6>
                          </div>
                          <b-card
                            v-if="area.dft_realization"
                            v-b-popover.hover.top="'Realisasi dari Dry Report'"
                            class="m-0 p-50 text-right ml-1"
                            no-body
                            style="background: #EFF5EA"
                          >
                            <small class="text-primary">Realisasi</small>
                            <h6 class="text-primary font-weight-bolder m-0">
                              <span class="font-weight-normal">DFT</span> {{ area.dft_realization }} μm
                            </h6>
                          </b-card>
                        </div>
                        <div
                          v-if="area.surface_preparation"
                          class="mb-50"
                        >
                          <small class="text-muted">
                            Surface Preparation
                          </small>
                          <h6 class="m-0">
                            {{ area.surface_preparation }}
                          </h6>
                        </div>
                        <div
                          v-if="area.notes"
                          class="mb-1"
                        >
                          <small class="text-muted">
                            Notes
                          </small>
                          <p
                            style="white-space: pre-line"
                            class="m-0"
                          >
                            {{ area.notes }}
                          </p>
                        </div>
                        <vue-good-table
                          :columns="area.category.name.toLowerCase() === 'free item' || area.category.name.toLowerCase() === 'anchor' ? freeItemTableColumn : itemTableColumn"
                          :rows="area.items"
                          :sort-options="{ enabled: false }"
                          :pagination-options="{
                            enabled: false,
                          }"
                          style-class="vgt-table mt-50"
                        >
                          <div slot="emptystate">
                            <small class="text-center d-block">Tidak ada item</small>
                          </div>
                          <template
                            slot="table-row"
                            slot-scope="props"
                          >
                            <span v-if="props.column.field === 'item_name'">
                              <div class="d-flex align-items-center">
                                <div>
                                  <router-link :to="{ name: 'item-detail', params: {id: props.row.item_id.toString()} }">
                                    <h6 class="text-primary m-0">
                                      {{ props.row.item_name }}
                                    </h6>
                                  </router-link>
                                  <small class="text-muted d-block">
                                    {{ props.row.item_code }} {{ props.row.packaging && props.row.packaging.liter ? `(${props.row.packaging.liter} liter)` : '' }}
                                  </small>
                                </div>
                                <b-avatar
                                  v-if="props.row.agent_item || props.row.agent_item_2"
                                  :id="`popover-${indexArea}-${props.row.originalIndex}`"
                                  variant="light-primary"
                                  :size="28"
                                  class="ml-75"
                                >
                                  <feather-icon
                                    icon="LinkIcon"
                                    size="16"
                                  />
                                </b-avatar>
                                <b-popover
                                  v-if="props.row.agent_item || props.row.agent_item_2"
                                  :target="`popover-${indexArea}-${props.row.originalIndex}`"
                                  triggers="hover focus"
                                  placement="topright"
                                  boundary="viewport"
                                >
                                  <small class="text-primary">
                                    Berpasangan dengan agent item
                                  </small>
                                  <table
                                    border="0"
                                    class="mt-75"
                                    style="border-color: #dbdbdb"
                                  >
                                    <tr>
                                      <th class="pr-75">
                                        Item
                                      </th>
                                      <th>
                                        Vol.
                                      </th>
                                    </tr>
                                    <tr>
                                      <td class="py-50 pr-1">
                                        <small class="font-weight-bold d-block">
                                          {{ props.row.item_name }}
                                        </small>
                                        <small class="text-muted">
                                          {{ props.row.item_code }} {{ props.row.packaging && props.row.packaging.liter ? `(${props.row.packaging.liter} liter)` : '' }}
                                        </small>
                                      </td>
                                      <td class="py-50">
                                        <h6 class="m-0">
                                          {{ props.row.liter_detail.item }}L
                                        </h6>
                                      </td>
                                    </tr>
                                    <tr
                                      v-if="props.row.agent_item"
                                    >
                                      <td class="py-50 pr-1">
                                        <small class="font-weight-bold d-block">
                                          {{ props.row.agent_item.name }}
                                        </small>
                                        <small class="text-muted">
                                          {{ props.row.agent_item.no }} {{ props.row.agent_item.packaging && props.row.agent_item.packaging.liter ? `(${props.row.agent_item.packaging.liter} liter)` : '' }}
                                        </small>
                                      </td>
                                      <td class="py-50">
                                        <h6 class="m-0">
                                          {{ props.row.liter_detail.agent_item }}L
                                        </h6>
                                      </td>
                                    </tr>
                                    <tr
                                      v-if="props.row.agent_item_2"
                                    >
                                      <td class="py-50 pr-1">
                                        <small class="font-weight-bold d-block">
                                          {{ props.row.agent_item_2.name }}
                                        </small>
                                        <small class="text-muted">
                                          {{ props.row.agent_item_2.no }} {{ props.row.agent_item_2.packaging && props.row.agent_item_2.packaging.liter ? `(${props.row.agent_item_2.packaging.liter} liter)` : '' }}
                                        </small>
                                      </td>
                                      <td class="py-50">
                                        <h6 class="m-0">
                                          {{ props.row.liter_detail.agent_item_2 }}L
                                        </h6>
                                      </td>
                                    </tr>
                                  </table>
                                </b-popover>
                              </div>
                            </span>
                            <span v-else-if="props.column.field === 'color'">
                              <div v-if="props.row.color">
                                <h6 class="m-0">{{ props.row.color.eng_name }}</h6>
                                <small>{{ props.row.color.id_ral }}</small>
                                <div
                                  v-if="props.row.color.hex_code"
                                  v-b-popover.hover.top="`#${props.row.color.hex_code}`"
                                  class="mt-50"
                                  :style="`height: 6px; width: 40px; border-radius: 2px; background: #${props.row.color.hex_code}`"
                                />
                              </div>
                            </span>
                            <span v-else-if="props.column.field === 'coat'">
                              <div v-if="props.row.item_type.id === 1">
                                <h6 class="m-0">{{ props.row.coat ? `${props.row.coat} %` : '' }}</h6>
                              </div>
                            </span>
                            <span v-else-if="props.column.field === 'dry_film_thickness'">
                              <div v-if="props.row.item_type.id === 1">
                                <h6 class="m-0">{{ props.row.dry_film_thickness ? `${props.row.dry_film_thickness} μm` : '' }}</h6>
                              </div>
                            </span>
                            <span v-else-if="props.column.field === 'theoritical'">
                              <div v-if="props.row.item_type.id === 1">
                                <h6 class="m-0">{{ props.row.theoritical }}</h6>
                              </div>
                            </span>
                            <span v-else-if="props.column.field === 'loss'">
                              <div v-if="props.row.item_type.id === 1">
                                <h6 class="m-0">{{ props.row.loss ? `${props.row.loss} %` : '' }}</h6>
                              </div>
                            </span>
                            <span v-else-if="props.column.field === 'practical'">
                              <h6 class="m-0">{{ props.row.practical }}</h6>
                            </span>
                            <span v-else-if="props.column.field === 'thinner_code'">
                              <h6 class="m-0">{{ props.row.thinner_code }}</h6>
                            </span>
                            <span v-else-if="props.column.field === 'liter'">
                              <h6 class="m-0">{{ props.row.liter ? `${props.row.liter} L` : '' }}</h6>
                            </span>
                            <span v-else-if="props.column.field === 'price'">
                              <h6 class="m-0">{{ formatCurrency(props.row.price) }}</h6>
                              <small
                                v-if="permission.viewHpp"
                                class="text-muted"
                              >
                                {{ formatCurrency(props.row.hpp) }}
                              </small>
                            </span>
                            <span v-else-if="props.column.field === 'subtotal'">
                              <h6 class="m-0">{{ formatCurrency(props.row.subtotal) }}</h6>
                              <small
                                v-if="permission.viewHpp"
                                class="text-muted"
                              >
                                {{ formatCurrency(props.row.subtotal_hpp) }}
                              </small>
                            </span>
                            <span v-else-if="props.column.field === 'data_sheet'">
                              <b-button
                                v-if="props.row.data_sheet"
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                size="sm"
                                variant="outline-danger"
                                class="w-50"
                                @click="$refs.pdf.show(props.row.data_sheet)"
                              >
                                <b-img
                                  :src="require('@/assets/images/icons/pdf.png')"
                                  height="auto"
                                  width="18"
                                />
                              </b-button>
                            </span>
                          </template>
                          <div
                            v-if="area.category.name.toLowerCase() !== 'free item' && area.category.name.toLowerCase() !== 'anchor'"
                            slot="table-actions-bottom"
                            class="p-1"
                          >
                            <b-row>
                              <b-col
                                class="d-flex"
                                cols="12"
                                md="6"
                              >
                                <h6 class="text-muted mr-1">
                                  Subtotal DFT
                                </h6>
                                <h6>{{ area.total_dft }} μm</h6>
                              </b-col>
                              <b-col
                                class="d-flex"
                                cols="12"
                                md="6"
                              >
                                <h6 class="text-muted mr-1">
                                  Subtotal Vol.
                                </h6>
                                <h6>{{ area.total_volume }}</h6>
                              </b-col>
                              <b-col
                                class="d-flex"
                                cols="12"
                                md="6"
                              >
                                <h6 class="text-muted mr-1">
                                  Subtotal
                                </h6>
                                <h6>{{ formatCurrency(area.total) }}</h6>
                              </b-col>
                              <b-col
                                v-if="permission.viewHpp"
                                class="d-flex"
                                cols="12"
                                md="6"
                              >
                                <h6 class="text-muted mr-1">
                                  Subtotal HPP
                                </h6>
                                <h6>{{ formatCurrency(area.total_hpp) }}</h6>
                              </b-col>
                            </b-row>
                          </div>
                        </vue-good-table>
                      </b-card>
                    </div>
                  </b-card-body>
                </XyzTransitionGroup>
              </b-card>
            </XyzTransition>
          </b-col>
          <b-col
            cols="12"
            xl="3"
          >
            <sticky-panel>
              <XyzTransition
                appear
                xyz="fade right-3 delay-2"
              >
                <b-overlay :show="loadingStatus">
                  <b-card>
                    <b-tabs>
                      <b-tab title="Actions">
                        <div class="d-flex justify-content-between mb-50">
                          <div>
                            <small class="d-block">Status</small>
                            <b-badge :variant="statusOptions[quotationData.status.id].badge">
                              {{ statusOptions[quotationData.status.id].label }}
                            </b-badge>
                          </div>
                          <div class="text-right">
                            <h6
                              class="font-weight-bolder m-0"
                              :class="{ 'text-primary cursor-pointer' : quotationData.last_version > 1 }"
                              @click="compareVersion"
                            >
                              Versi {{ version }}
                              <feather-icon
                                v-if="quotationData.last_version > 1"
                                icon="RotateCcwIcon"
                                class="mb-25"
                              />
                            </h6>
                            <small
                              v-if="version < quotationData.last_version"
                              class="cursor-pointer"
                              @click="$router.push({ name: 'quotation-detail', params: {id: id, version: quotationData.last_version.toString()} })"
                            >
                              Versi terbaru : {{ quotationData.last_version }}
                              <feather-icon
                                icon="ArrowRightIcon"
                                class="mb-25"
                              />
                            </small>
                          </div>
                        </div>
                        <div
                          v-if="quotationData.status.id === 7 && quotationData.sales_order_id"
                          class="salesorder-card p-75 mt-1 cursor-pointer"
                          @click="$router.push({ name: 'salesorder-detail', params: { id: quotationData.sales_order_id } })"
                        >
                          <div class="d-flex justify-content-between flex-wrap">
                            <h6 class="text-primary m-0">
                              {{ quotationData.sales_order_number }}
                            </h6>
                            <b-badge
                              :variant="getSalesOrderStatusVariant(quotationData.sales_order_status)"
                            >
                              {{ quotationData.sales_order_status }}
                            </b-badge>
                          </div>
                          <small class="text-muted">
                            {{ quotationData.sales_order_transDate ? `Tanggal : ${quotationData.sales_order_transDate}` : '' }}
                          </small>
                        </div>
                        <hr>
                        <div class="d-flex justify-content-between mt-1 align-items-center">
                          <small class="font-weight-bolder">Total Volume</small>
                          <h6 class="font-weight-normal m-0">
                            {{ quotationData.total_volume }} L
                          </h6>
                        </div>
                        <div class="d-flex justify-content-between mt-1 align-items-center">
                          <small class="font-weight-bolder">Subtotal</small>
                          <h6 class="font-weight-normal m-0">
                            {{ formatCurrency(quotationData.discount ? quotationData.total + quotationData.discount : quotationData.total) }}
                          </h6>
                        </div>
                        <div
                          v-if="quotationData.discount"
                          class="d-flex justify-content-between mt-1 align-items-center"
                        >
                          <small class="font-weight-bolder">Diskon</small>
                          <h6 class="font-weight-normal m-0">
                            {{ `- ${formatCurrency(quotationData.discount)}` }}
                          </h6>
                        </div>
                        <div class="d-flex justify-content-between mt-1 align-items-center">
                          <small class="font-weight-bolder">Total</small>
                          <h5 class="font-weight-bolder text-primary m-0">
                            {{ formatCurrency(quotationData.total) }}
                          </h5>
                        </div>
                        <div
                          v-if="permission.viewHpp"
                          class="d-flex justify-content-between mt-1 align-items-center"
                        >
                          <small class="font-weight-bolder">Total HPP</small>
                          <h6 class="font-weight-bold text-secondary m-0">
                            {{ formatCurrency(quotationData.total_hpp) }}
                          </h6>
                        </div>
                        <div v-if="version >= quotationData.last_version">
                          <hr v-if="quotationData.status.id !== 6 && resolveUserPermission()">
                          <b-button
                            v-if="quotationData.status.id === 2 && $root.role === 2 && quotationData.userCreate.id !== $store.getters.getCurrentUser.user.id"
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="danger"
                            block
                            @click="acceptRejectQuote(0)"
                          >
                            Reject
                          </b-button>
                          <b-button
                            v-if="quotationData.status.id === 3 && resolveUserPermission()"
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="outline-danger"
                            block
                            @click="rejectByCustomer()"
                          >
                            Rejected by Customer
                          </b-button>
                          <b-button
                            v-if="quotationData.status.id >= 1 && quotationData.status.id <= 5 && quotationData.status.id !== 3 && resolveUserPermission()"
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="warning"
                            block
                            @click="closeQuote()"
                          >
                            Close
                          </b-button>
                          <b-button
                            v-if="quotationData.status.id === 7 && resolveUserPermission()"
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="outline-warning"
                            block
                            @click="cancelQuote()"
                          >
                            Cancel
                          </b-button>
                          <b-button
                            v-if="quotationData.status.id === 3 && resolveUserPermission()"
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="info"
                            block
                            @click="openSalesOrderForm()"
                          >
                            Forward
                          </b-button>
                          <b-button
                            v-if="quotationData.status.id === 5 && resolveUserPermission()"
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="outline-info"
                            block
                            @click="reviseQuote()"
                          >
                            Revise
                          </b-button>
                          <b-button
                            v-if="(quotationData.status.id === 1 || quotationData.status.id === 4) && resolveUserPermission()"
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="outline-warning"
                            block
                            @click="editQuote()"
                          >
                            Edit
                          </b-button>
                          <b-button
                            v-if="(quotationData.status.id === 1 || quotationData.status.id === 4) && resolveUserPermission()"
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="primary"
                            block
                            @click="submitQuote()"
                          >
                            Submit
                          </b-button>
                          <b-button
                            v-if="quotationData.status.id === 2 && $root.role === 2 && quotationData.userCreate.id !== $store.getters.getCurrentUser.user.id"
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="outline-primary"
                            block
                            @click="acceptRejectQuote(1)"
                          >
                            Approve
                          </b-button>
                          <hr>
                          <b-row>
                            <b-col
                              :class="{ 'pr-50' : resolveUserPermission() }"
                            >
                              <b-dropdown
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                v-b-popover.hover.top="'Preview Quotation'"
                                split
                                variant="outline-primary"
                                block
                                @click="$refs.pdf.show(`${$restHTTP}/rest/forward/${id}`, quotationData.name)"
                              >
                                <template #button-content>
                                  <feather-icon
                                    icon="FileTextIcon"
                                    size="18"
                                  />
                                </template>
                                <b-dropdown-form
                                  v-b-popover.hover.top="'Tampilkan loss factor pada PDF report'"
                                >
                                  <div class="d-flex align-items-center">
                                    <span class="mr-auto">Loss Factor</span>
                                    <b-form-checkbox
                                      v-model="showLossFactorReport"
                                      switch
                                    />
                                  </div>
                                </b-dropdown-form>
                                <hr>
                                <b-dropdown-item @click="$refs.pdf.show(`${$restHTTP}/rest/forward/${id}?type=1&isLossFactorVisible=${showLossFactorReport}`, quotationData.name)">
                                  Quote PDF
                                </b-dropdown-item>
                                <b-dropdown-item @click="$refs.pdf.show(`${$restHTTP}/rest/forward/${id}?type=2&isLossFactorVisible=${showLossFactorReport}`, quotationData.name)">
                                  Quote PDF (Tanpa Harga)
                                </b-dropdown-item>
                                <b-dropdown-item @click="$refs.pdf.show(`${$restHTTP}/rest/forward/${id}?type=3&isLossFactorVisible=${showLossFactorReport}`, quotationData.name)">
                                  Price List PDF
                                </b-dropdown-item>
                                <b-dropdown-item @click="$refs.pdf.show(`${$restHTTP}/rest/forward/${id}?type=4&isLossFactorVisible=${showLossFactorReport}`, quotationData.name)">
                                  PDF Per M2
                                </b-dropdown-item>
                              </b-dropdown>
                            </b-col>
                            <b-col
                              v-if="resolveUserPermission()"
                              class="pl-50"
                            >
                              <b-button
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                v-b-popover.hover.top="'Kirim PDF Quotation'"
                                block
                                variant="primary"
                                @click="$refs.intermediateForm.show(quotationData)"
                              >
                                <feather-icon
                                  icon="SendIcon"
                                  size="18"
                                />
                              </b-button>
                            </b-col>
                          </b-row>
                        </div>
                      </b-tab>
                      <b-tab
                        lazy
                        title="Logs"
                      >
                        <div
                          class="mt-2 logs-list"
                          :style="`height: ${quotationData.quoteLogs.length > 6 ? '60vh' : 'auto'}`"
                        >
                          <vue-perfect-scrollbar
                            v-if="quotationData.quoteLogs.length"
                            :settings="{
                              maxScrollbarLength: 300,
                            }"
                            class="list-group scroll-area overflow-hidden"
                          >
                            <quotation-logs :logs="quotationData.quoteLogs" />
                          </vue-perfect-scrollbar>
                          <h6
                            v-else
                            class="text-muted text-center"
                          >
                            Quote ini belum ada logs
                          </h6>
                        </div>
                      </b-tab>
                    </b-tabs>
                  </b-card>
                </b-overlay>
              </XyzTransition>
            </sticky-panel>
          </b-col>
        </b-row>

        <compare-version
          :id="+id"
          ref="compareVersion"
          :last-version="quotationData.last_version"
          :quote-data="quotationData"
        />
      </div>

      <pdf-preview
        ref="pdf"
      />
      <vue-bottom-sheet
        ref="salesOrder"
        :click-to-close="false"
        block
        max-width="90%"
      >
        <validation-observer ref="validateForward">
          <div
            v-if="quoteDetailSO"
            class="bottom-sheet"
          >
            <div class="d-flex justify-content-between mb-1 align-content-center">
              <h4>Forward Sales Order</h4>
              <feather-icon
                icon="XIcon"
                size="28"
                class="text-primary"
                @click="$refs.salesOrder.close()"
              />
            </div>
            <b-row>
              <b-col
                cols="12"
                md="5"
              >
                <h6>Customer</h6>
                <router-link :to="{ name: 'customer-detail', params: {id: quotationData.project.customer.id.toString()} }">
                  <h6 class="text-primary m-0 mb-2 mb-md-0">
                    {{ quotationData.project.customer.name }}
                  </h6>
                </router-link>
              </b-col>
              <b-col
                cols="12"
                md="5"
              >
                <h6>Project</h6>
                <router-link :to="{ name: 'project-detail', params: {id: quotationData.project.id.toString()} }">
                  <h6 class="text-primary m-0 mb-2 mb-md-0">
                    {{ quotationData.project.name }}
                  </h6>
                </router-link>
              </b-col>
              <b-col
                cols="12"
                md="2"
                class="text-left text-md-right"
              >
                <h6>Tanggal Transaksi</h6>
                {{ formatDate(new Date(), true) }}
              </b-col>
            </b-row>
            <hr>
            <h6 class="text-primary">
              Main Items
            </h6>
            <div
              v-for="(item, index) in quoteDetailSO.items"
              :key="index"
            >
              <b-row align-v="start">
                <b-col
                  cols="12"
                  md="3"
                  class="d-flex align-items-start justify-content-between mb-1"
                >
                  <div>
                    <small class="text-muted">Nama Item</small>
                    <h5 class="m-0 mb-25">
                      {{ item.item_name }}
                    </h5>
                    <h6 class="m-0">
                      {{ formatCurrency(item.price) }}
                    </h6>
                    <h6
                      v-if="item.error"
                      class="text-danger mt-1"
                    >
                      {{ item.error }}
                    </h6>
                  </div>
                  <div class="d-flex flex-column">
                    <small class="text-muted text-right">Total Vol.</small>
                    <h4 class="m-0 text-right">
                      {{ item.total_liter }}
                    </h4>
                    <b-avatar
                      v-if="item.agent_item || item.agent_item_2"
                      :id="`popover-forward-${index}`"
                      variant="light-primary"
                      :size="28"
                      class="mt-75 ml-auto"
                    >
                      <feather-icon
                        icon="LinkIcon"
                        size="16"
                      />
                    </b-avatar>
                    <b-popover
                      v-if="item.agent_item || item.agent_item_2"
                      :target="`popover-forward-${index}`"
                      triggers="hover focus"
                      placement="topright"
                      boundary="viewport"
                      style="width: 600px"
                    >
                      <small class="text-primary">
                        Berpasangan dengan agent item
                      </small>
                      <table
                        border="0"
                        class="mt-75"
                        style="border-color: #dbdbdb"
                      >
                        <tr>
                          <th class="pr-75">
                            Item
                          </th>
                          <th>
                            Vol.
                          </th>
                        </tr>
                        <tr>
                          <td class="py-50 pr-1">
                            <small class="font-weight-bold d-block">
                              {{ item.item_name }}
                            </small>
                          </td>
                          <td class="py-50">
                            <h6 class="m-0">
                              {{ item.item_liter ? item.item_liter + 'L' : '0L' }}
                            </h6>
                          </td>
                        </tr>
                        <tr
                          v-for="packaging in item.packagings"
                          :key="packaging.item_code"
                        >
                          <td class="pr-1">
                            <small class="font-weight-bold d-block text-muted">
                              {{ packaging.item_code }}
                            </small>
                          </td>
                          <td>
                            <small class="font-weight-bold">
                              {{ findMainLiter(item, packaging) }}
                            </small>
                          </td>
                        </tr>
                        <tr
                          v-if="item.agent_item"
                        >
                          <td class="py-50 pr-1">
                            <small class="font-weight-bold d-block">
                              {{ item.agent_item.item_name }}
                            </small>
                          </td>
                          <td class="py-50">
                            <h6 class="m-0">
                              {{ item.agent_item.item_liter ? item.agent_item.item_liter + 'L' : '0L' }}
                            </h6>
                          </td>
                        </tr>
                        <template v-if="item.agent_item">
                          <tr
                            v-for="packaging in item.agent_item.packagings"
                            :key="packaging.item_code"
                          >
                            <td class="pr-1">
                              <small class="font-weight-bold d-block text-muted">
                                {{ packaging.item_code }}
                              </small>
                            </td>
                            <td>
                              <small class="font-weight-bold">
                                {{ packaging.volume ? packaging.volume + 'L' : '0L' }}
                              </small>
                            </td>
                          </tr>
                        </template>
                        <tr
                          v-if="item.agent_item_2"
                        >
                          <td class="py-50 pr-1">
                            <small class="font-weight-bold d-block">
                              {{ item.agent_item_2.item_name }}
                            </small>
                          </td>
                          <td class="py-50">
                            <h6 class="m-0">
                              {{ item.agent_item_2.item_liter ? item.agent_item_2.item_liter + 'L' : '0 L' }}
                            </h6>
                          </td>
                        </tr>
                        <template v-if="item.agent_item_2">
                          <tr
                            v-for="packaging in item.agent_item_2.packagings"
                            :key="packaging.item_code"
                          >
                            <td class="pr-1">
                              <small class="font-weight-bold d-block text-muted">
                                {{ packaging.item_code }}
                              </small>
                            </td>
                            <td>
                              <small class="font-weight-bold">
                                {{ packaging.volume ? packaging.volume + 'L' : '0 L' }}
                              </small>
                            </td>
                          </tr>
                        </template>
                      </table>
                    </b-popover>
                  </div>
                </b-col>
                <b-col
                  cols="12"
                  md="7"
                >
                  <b-card
                    v-for="(packaging, indexPackaging) in item.packagings"
                    :key="indexPackaging"
                    class="m-0"
                    :class="{ 'mb-75' : indexPackaging !== item.packagings.length - 1 }"
                    no-body
                    border-variant="light"
                  >
                    <div class="d-flex flex-wrap align-items-center justify-content-between p-1">
                      <div class="flex-grow-1">
                        <small class="text-muted">{{ packaging.item_code }}</small>
                        <h6 class="m-0">
                          <span v-if="item.agent_item || item.agent_item_2">Set</span> {{ packaging.packaging_name }} {{ packaging.uniteLiter }} Liter
                        </h6>
                        <small
                          v-if="item.agent_item || item.agent_item_2"
                          class="text-muted d-block"
                        >{{ captionSetLiter(packaging, item.agent_item, item.agent_item_2) }}</small>
                        <small>Stok : {{ packaging.stock }}</small>
                      </div>
                      <div class="mr-2 flex-grow-1">
                        <div class="d-flex">
                          <small class="text-muted ml-auto">Qty</small>
                        </div>
                        <h6 class="m-0 text-right">
                          {{ packaging.qty }}
                        </h6>
                      </div>
                      <div class="flex-grow-1">
                        <small class="text-muted m-0">
                          Liter
                        </small>
                        <custom-spin-input
                          :value="quoteDetailSO.items[index].packagings[indexPackaging].liter_input"
                          :error="item.error !== null"
                          @input="handleSpinInput($event, index, indexPackaging)"
                        />
                      </div>
                    </div>
                  </b-card>
                </b-col>
                <b-col
                  cols="12"
                  md="2"
                  class="text-left text-md-right mt-1 mt-md-0"
                >
                  <div>
                    <small class="text-muted">
                      Total
                    </small>
                    <h5>{{ formatCurrency(item.subtotal) }}</h5>
                  </div>
                </b-col>
              </b-row>
              <hr>
            </div>
            <h6
              v-if="quoteDetailSO.free_items.length"
              class="text-primary"
            >
              Free Items
            </h6>
            <div
              v-for="(item, index) in quoteDetailSO.free_items"
              :key="`free${index}`"
            >
              <b-row align-v="start">
                <b-col
                  cols="12"
                  md="3"
                  class="d-flex align-items-start justify-content-between mb-1"
                >
                  <div>
                    <small class="text-muted">Nama Item</small>
                    <h5 class="m-0 mb-25">
                      {{ item.item_name }}
                    </h5>
                    <h6 class="m-0">
                      {{ formatCurrency(item.price) }}
                    </h6>
                    <h6
                      v-if="item.error"
                      class="text-danger mt-1"
                    >
                      {{ item.error }}
                    </h6>
                  </div>
                  <div class="d-flex flex-column">
                    <small class="text-muted text-right">Total Vol.</small>
                    <h4 class="m-0 text-right">
                      {{ item.total_liter }}
                    </h4>
                    <b-avatar
                      v-if="item.agent_item || item.agent_item_2"
                      :id="`popover-forward-free-${index}`"
                      variant="light-primary"
                      :size="28"
                      class="mt-75 ml-auto"
                    >
                      <feather-icon
                        icon="LinkIcon"
                        size="16"
                      />
                    </b-avatar>
                    <b-popover
                      v-if="item.agent_item || item.agent_item_2"
                      :target="`popover-forward-free-${index}`"
                      triggers="hover focus"
                      placement="topright"
                      boundary="viewport"
                      style="width: 600px"
                    >
                      <small class="text-primary">
                        Berpasangan dengan agent item
                      </small>
                      <table
                        border="0"
                        class="mt-75"
                        style="border-color: #dbdbdb"
                      >
                        <tr>
                          <th class="pr-75">
                            Item
                          </th>
                          <th>
                            Vol.
                          </th>
                        </tr>
                        <tr>
                          <td class="py-50 pr-1">
                            <small class="font-weight-bold d-block">
                              {{ item.item_name }}
                            </small>
                          </td>
                          <td class="py-50">
                            <h6 class="m-0">
                              {{ item.item_liter ? item.item_liter + 'L' : '0L' }}
                            </h6>
                          </td>
                        </tr>
                        <tr
                          v-for="packaging in item.packagings"
                          :key="packaging.item_code"
                        >
                          <td class="pr-1">
                            <small class="font-weight-bold d-block text-muted">
                              {{ packaging.item_code }}
                            </small>
                          </td>
                          <td>
                            <small class="font-weight-bold">
                              {{ findMainLiter(item, packaging) }}
                            </small>
                          </td>
                        </tr>
                        <tr
                          v-if="item.agent_item"
                        >
                          <td class="py-50 pr-1">
                            <small class="font-weight-bold d-block">
                              {{ item.agent_item.item_name }}
                            </small>
                          </td>
                          <td class="py-50">
                            <h6 class="m-0">
                              {{ item.agent_item.item_liter ? item.agent_item.item_liter + 'L' : '0L' }}
                            </h6>
                          </td>
                        </tr>
                        <template v-if="item.agent_item">
                          <tr
                            v-for="packaging in item.agent_item.packagings"
                            :key="packaging.item_id"
                          >
                            <td class="pr-1">
                              <small class="font-weight-bold d-block text-muted">
                                {{ packaging.item_code }}
                              </small>
                            </td>
                            <td>
                              <small class="font-weight-bold">
                                {{ packaging.volume ? packaging.volume + 'L' : '0L' }}
                              </small>
                            </td>
                          </tr>
                        </template>
                        <tr
                          v-if="item.agent_item_2"
                        >
                          <td class="py-50 pr-1">
                            <small class="font-weight-bold d-block">
                              {{ item.agent_item_2.item_name }}
                            </small>
                          </td>
                          <td class="py-50">
                            <h6 class="m-0">
                              {{ item.agent_item_2.item_liter ? item.agent_item_2.item_liter + 'L' : '0 L' }}
                            </h6>
                          </td>
                        </tr>
                        <template v-if="item.agent_item_2">
                          <tr
                            v-for="packaging in item.agent_item_2.packagings"
                            :key="packaging.item_id"
                          >
                            <td class="pr-1">
                              <small class="font-weight-bold d-block text-muted">
                                {{ packaging.item_code }}
                              </small>
                            </td>
                            <td>
                              <small class="font-weight-bold">
                                {{ packaging.volume ? packaging.volume + 'L' : '0 L' }}
                              </small>
                            </td>
                          </tr>
                        </template>
                      </table>
                    </b-popover>
                  </div>
                </b-col>
                <b-col
                  cols="12"
                  md="7"
                >
                  <b-card
                    v-for="(packaging, indexPackaging) in item.packagings"
                    :key="indexPackaging"
                    class="m-0"
                    :class="{ 'mb-75' : indexPackaging !== item.packagings.length - 1 }"
                    no-body
                    border-variant="light"
                  >
                    <div class="d-flex flex-wrap align-items-center justify-content-between p-1">
                      <div class="flex-grow-1">
                        <small class="text-muted">{{ packaging.item_code }}</small>
                        <h6 class="m-0">
                          <span v-if="item.agent_item || item.agent_item_2">Set</span> {{ packaging.packaging_name }} {{ packaging.uniteLiter }} Liter
                        </h6>
                        <small
                          v-if="item.agent_item || item.agent_item_2"
                          class="text-muted d-block"
                        >{{ captionSetLiter(packaging, item.agent_item, item.agent_item_2) }}</small>
                        <small>Stok : {{ packaging.stock }}</small>
                      </div>
                      <div class="mr-2 flex-grow-1">
                        <div class="d-flex">
                          <small class="text-muted ml-auto">Qty</small>
                        </div>
                        <h6 class="m-0 text-right">
                          {{ packaging.qty }}
                        </h6>
                      </div>
                      <div class="flex-grow-1">
                        <small class="text-muted m-0">
                          Liter
                        </small>
                        <custom-spin-input
                          :value="quoteDetailSO.free_items[index].packagings[indexPackaging].liter_input"
                          :error="item.error !== null"
                          @input="handleFreeSpinInput($event, index, indexPackaging)"
                        />
                      </div>
                    </div>
                  </b-card>
                </b-col>
                <b-col
                  cols="12"
                  md="2"
                  class="text-left text-md-right mt-1 mt-md-0"
                >
                  <div>
                    <small class="text-muted">
                      Total
                    </small>
                    <h5>{{ formatCurrency(item.subtotal) }}</h5>
                  </div>
                </b-col>
              </b-row>
              <hr>
            </div>
            <h6
              v-b-toggle.detail
              class="text-primary"
            >
              Detail Tambahan<feather-icon
                icon="ChevronRightIcon"
                size="18"
                class="mb-25"
              />
            </h6>
            <b-collapse
              id="detail"
              visible
            >
              <b-row>
                <b-col
                  cols="12"
                  lg="4"
                >
                  <b-form-group label="Customer No.">
                    <validation-provider
                      #default="{ errors }"
                      name="Customer no."
                      rules="required"
                    >
                      <b-form-input
                        v-model="quoteDetailSO.customerNo"
                        placeholder="Customer No"
                        disabled
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  lg="4"
                >
                  <b-form-group label="Tanggal Transaksi">
                    <validation-provider
                      #default="{ errors }"
                      name="Tanggal transaksi"
                      rules="required"
                    >
                      <flat-pickr
                        v-model="quoteDetailSO.transDate"
                        class="form-control"
                        placeholder="Pilih tanggal"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  lg="4"
                >
                  <b-form-group label="Tanggal Pengiriman">
                    <b-input-group class="input-group-merge">
                      <flat-pickr
                        v-model="quoteDetailSO.shipDate"
                        class="form-control"
                        placeholder="Pilih tanggal"
                      />
                      <b-input-group-append>
                        <b-button
                          variant="light"
                          @click="quoteDetailSO.shipDate = ''"
                        >
                          <feather-icon
                            icon="XIcon"
                            class="text-secondary"
                            size="16"
                          />
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  lg="4"
                >
                  <b-form-group label="Syarat pembayaran">
                    <validation-provider
                      #default="{ errors }"
                      name="Syarat pembayaran"
                      rules="required"
                    >
                      <v-select
                        v-model="quoteDetailSO.paymentTermName"
                        class="w-100 mb-1"
                        :options="paymentTermOptions"
                        label="name"
                        placeholder="Syarat pembayaran"
                        :clearable="false"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  lg="4"
                >
                  <b-form-group label="PO Number">
                    <b-form-input
                      v-model="quoteDetailSO.poNumber"
                      placeholder="PO Number"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  lg="4"
                >
                  <b-form-group label="Nama Pengiriman">
                    <validation-provider
                      #default="{ errors }"
                      name="Nama pengiriman"
                      rules="required"
                    >
                      <v-select
                        v-model="quoteDetailSO.shipmentName"
                        class="w-100 mb-1"
                        :options="shipmentOptions"
                        label="name"
                        placeholder="Nama pengiriman"
                        :clearable="false"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  lg="4"
                >
                  <b-form-group label="Tipe">
                    <validation-provider
                      #default="{ errors }"
                      name="Tipe"
                      rules="required"
                    >
                      <v-select
                        v-model="quoteDetailSO.type"
                        class="w-100 mb-1"
                        :options="salesOrderTypes"
                        label="name"
                        placeholder="Pilih Tipe"
                        :clearable="false"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  lg="4"
                >
                  <b-form-group label="Kategori">
                    <validation-provider
                      #default="{ errors }"
                      name="Kategori"
                      rules="required"
                    >
                      <v-select
                        v-model="quoteDetailSO.category"
                        class="w-100 mb-1"
                        :options="salesOrderCategories"
                        label="name"
                        placeholder="Pilih Kategori"
                        :clearable="false"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  lg="4"
                >
                  <b-form-group label="Alamat Pengiriman">
                    <b-form-textarea
                      v-model="quoteDetailSO.toAddress"
                      placeholder="Alamat Pengiriman"
                      rows="2"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  lg="4"
                >
                  <b-form-group label="Keterangan">
                    <b-form-textarea
                      v-model="quoteDetailSO.notes"
                      placeholder="Keterangan"
                      rows="2"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-collapse>
            <hr>
          </div>
          <div
            v-else
            key="1"
            class="d-flex align-items-center justify-content-center"
            style="height: 90vh"
          >
            <b-spinner variant="primary" />
          </div>
          <b-overlay
            :show="loadingForward"
          >
            <b-card
              class="mb-0"
            >
              <h6
                v-if="!isInAccurate && $root.role === 1"
                class="text-danger text-center font-weight-normal mb-2"
              >
                Customer yang dipilih belum ditambah ke Accurate, hubungi manager / sales admin untuk menambah customer.
              </h6>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class="float-right w-100"
                variant="primary"
                :disabled="!isInAccurate && $root.role === 1"
                @click="validateForward()"
              >
                Submit Sales Order <feather-icon icon="ArrowRightIcon" />
              </b-button>
            </b-card>
          </b-overlay>
        </validation-observer>
      </vue-bottom-sheet>

      <b-modal
        id="confirmForward"
        ref="confirmForward"
        title="Konfirmasi Forward Sales Order"
        centered
      >
        <h6 class="font-weight-normal text-muted">
          Harap cek kembali data forward sales order. Pastikan data sudah benar.
        </h6>
        <hr>
        <div class="d-flex justify-content-between mt-1 align-items-center">
          <small class="font-weight-bolder">Subtotal</small>
          <h6 class="font-weight-normal m-0">
            {{ formatCurrency(quotationData.discount ? quotationData.total + quotationData.discount : quotationData.total) }}
          </h6>
        </div>
        <div
          v-if="quotationData.discount"
          class="d-flex justify-content-between mt-1 align-items-center"
        >
          <small class="font-weight-bolder">Diskon</small>
          <h6 class="font-weight-normal m-0">
            {{ `- ${formatCurrency(quotationData.discount)}` }}
          </h6>
        </div>
        <div
          class="d-flex justify-content-between mt-1 align-items-center"
        >
          <small class="font-weight-bolder">Pajak</small>
          <h6 class="font-weight-normal m-0">
            {{ formatCurrency(11 / 100 * quotationData.total) }}
          </h6>
        </div>
        <div class="d-flex justify-content-between mt-1 align-items-center">
          <small class="font-weight-bolder">Total</small>
          <h5 class="font-weight-bolder text-primary m-0">
            {{ formatCurrency(quotationData.total + (11 / 100 * quotationData.total)) }}
          </h5>
        </div>
        <div
          v-if="permission.viewHpp"
          class="d-flex justify-content-between mt-1 align-items-center"
        >
          <small class="font-weight-bolder">Total HPP</small>
          <h6 class="font-weight-bold text-secondary m-0">
            {{ formatCurrency(quotationData.total_hpp) }}
          </h6>
        </div>

        <template
          #modal-footer
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="secondary"
            @click="$bvModal.hide('confirmForward'); $refs.salesOrder.open();"
          >
            Cek Kembali
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            @click="$bvModal.hide('confirmForward'); $refs.salesOrder.open(); submitForwardSO()"
          >
            Sudah Benar
          </b-button>
        </template>
      </b-modal>

      <intermediate-form
        ref="intermediateForm"
      />
    </div>
    <error-endpoint
      v-else
      :key="error"
      ref="error"
      :error="error"
    />
    <update-project-reminder ref="updateProjectReminder" />
  </div>
</template>

<script>
import {
  BCard, BCardBody, BRow, BCol, BTabs, BTab, BButton, BSpinner, BCollapse, BPopover, BFormCheckbox,
  VBToggle, BBadge, BOverlay, BAlert, BLink, BFormGroup, BFormInput, BFormTextarea, BAvatar, BDropdownForm,
  BImg, BProgress, VBPopover, BModal, BDropdown, BDropdownItem, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import BackNavigation from '@/components/misc/BackNavigation.vue'
import ErrorEndpoint from '@/components/misc/ErrorEndpoint.vue'
import StickyPanel from '@/components/misc/StickyPanel.vue'
import { mapActions, mapGetters } from 'vuex'
import { VueGoodTable } from 'vue-good-table'
import moment from 'moment'
import { formatCurrency, shortNumber } from '@core/utils/filter'
import Ripple from 'vue-ripple-directive'
import VueBottomSheet from '@webzlodimir/vue-bottom-sheet'
import CustomSpinInput from '@/components/input/CustomSpinInput.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required,
} from '@validations'
import UpdateProjectReminder from '@/views/project/UpdateProjectReminder.vue'
import PdfPreview from '@/components/misc/PdfPreview.vue'
import CompareVersion from './CompareVersion.vue'
import QuotationLogs from './QuotationLogs.vue'
import IntermediateForm from './IntermediateForm.vue'
import { captionSetLiter } from './useQuote'

export default {
  components: {
    ErrorEndpoint,
    PdfPreview,
    BCard,
    BCardBody,
    BRow,
    BCol,
    BTabs,
    BTab,
    BButton,
    BSpinner,
    BCollapse,
    BBadge,
    BOverlay,
    BAlert,
    BLink,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BImg,
    BProgress,
    BModal,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    BDropdownForm,
    flatPickr,
    BackNavigation,
    StickyPanel,
    VueGoodTable,
    VueBottomSheet,
    CustomSpinInput,
    vSelect,
    QuotationLogs,
    VuePerfectScrollbar,
    ValidationProvider,
    ValidationObserver,
    CompareVersion,
    IntermediateForm,
    BInputGroup,
    BInputGroupAppend,
    UpdateProjectReminder,
    BPopover,
    BAvatar,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-popover': VBPopover,
  },
  props: {
    id: {
      type: [String, Number],
      default: '',
    },
    version: {
      type: [String, Number],
      default: '',
    },
    openForwardQuote: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      required,
      loading: true,
      loadingStatus: false,
      loadingForward: false,
      formatCurrency,
      quotationData: '',
      quoteDetailSO: '',
      error: '',
      itemTableColumn: [
        {
          label: '',
          field: 'sort',
          type: 'number',
          sortable: false,
          hidden: true,
        },
        {
          label: 'Item',
          field: 'item_name',
          width: '240px',
          sortable: false,
        },
        {
          label: 'VS',
          field: 'vs_volume_solid',
          width: '120px',
          sortable: false,
        },
        {
          label: 'Warna',
          field: 'color',
          width: '140px',
          sortable: false,
        },
        {
          label: 'Coat',
          field: 'coat',
          width: '110px',
          sortable: false,
        },
        {
          label: 'DFT',
          field: 'dry_film_thickness',
          width: '110px',
          sortable: false,
        },
        {
          label: 'Theory',
          field: 'theoritical',
          sortable: false,
        },
        {
          label: 'Loss',
          field: 'loss',
          width: '110px',
          sortable: false,
        },
        {
          label: 'Practical',
          field: 'practical',
          sortable: false,
        },
        {
          label: 'Thinner',
          field: 'thinner_code',
          sortable: false,
        },
        {
          label: 'Volume',
          field: 'liter',
          width: '110px',
          sortable: false,
        },
        {
          label: 'Harga',
          field: 'price',
          sortable: false,
        },
        {
          label: 'Total',
          field: 'subtotal',
          sortable: false,
        },
        {
          label: 'Data Sheet',
          field: 'data_sheet',
          sortable: false,
          width: '160px',
        },
      ],
      freeItemTableColumn: [
        {
          label: '',
          field: 'sort',
          type: 'number',
          sortable: false,
          hidden: true,
        },
        {
          label: 'Item',
          field: 'item_name',
          width: '240px',
          sortable: false,
        },
        {
          label: 'Warna',
          field: 'color',
          width: '140px',
          sortable: false,
        },
        {
          label: 'Thinner',
          field: 'thinner_code',
          sortable: false,
        },
        {
          label: 'Volume',
          field: 'liter',
          width: '110px',
          sortable: false,
        },
        {
          label: 'Harga',
          field: 'price',
          sortable: false,
        },
        {
          label: 'Total',
          field: 'subtotal',
          sortable: false,
          width: '160px',
        },
        {
          label: 'Data Sheet',
          field: 'data_sheet',
          sortable: false,
          width: '160px',
        },
      ],
      paymentTermOptions: [],
      dryReport: '',
      showLossFactorReport: false,
    }
  },
  computed: {
    ...mapGetters({
      permission: 'getUserPermission',
      statusOptions: 'getStatusOptions',
      selecteddb: 'getSelectedDB',
      salesOrderStatusOptions: 'getSalesOrderStatusOptions',
      shipmentOptions: 'getShipmentOptions',
      salesOrderTypes: 'getSalesOrderTypes',
      salesOrderCategories: 'getSalesOrderCategories',
    }),
    isInAccurate() {
      if (!this.quotationData.project) return false
      if (this.quotationData.project.customer.id.startsWith('C')) return false
      return true
    },
  },
  async mounted() {
    await this.fetchDetail()

    if (this.$route.query && this.$route.query.action) {
      if (this.resolveUserPermission()) {
        const action = {
          accept: () => {
            if (this.quotationData.status.id === 2 && this.$root.role === 2 && this.quotationData.userCreate.id !== this.$store.getters.getCurrentUser.user.id) {
              this.acceptRejectQuote(1)
            }
          },
          reject: () => {
            if (this.quotationData.status.id === 2 && this.$root.role === 2 && this.quotationData.userCreate.id !== this.$store.getters.getCurrentUser.user.id) {
              this.acceptRejectQuote(0)
            }
          },
          rejectCustomer: () => {
            if (this.quotationData.status.id === 3) {
              this.rejectByCustomer()
            }
          },
          close: () => {
            if (this.quotationData.status.id >= 1 && this.quotationData.status.id <= 5 && this.quotationData.status.id !== 3) {
              this.closeQuote()
            }
          },
          cancel: () => {
            if (this.quotationData.status.id === 7) {
              this.cancelQuote()
            }
          },
          forward: () => {
            if (this.quotationData.status.id === 3) {
              this.openSalesOrderForm()
            }
          },
          submit: () => {
            if (this.quotationData.status.id === 1 || this.quotationData.status.id === 4) {
              this.submitQuote()
            }
          },
        }

        // eslint-disable-next-line no-prototype-builtins
        if (action.hasOwnProperty(this.$route.query.action)) {
          action[this.$route.query.action]()
          this.$router.replace({ name: this.$route.name, query: {} })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Aksi tidak ditemukan',
              icon: 'EditIcon',
              variant: 'warning',
            },
          })
          this.$router.replace({ name: this.$route.name, query: {} })
        }
      } else {
        this.$router.replace('/unauthorized')
      }
    }

    if (this.openForwardQuote) {
      this.openSalesOrderForm()
    }
  },
  methods: {
    shortNumber,
    async fetchDetail() {
      this.loading = true
      await this.getQuoteDetail({ quote_id: this.id, version: this.version }).then(async data => {
        if (data.reference_db.id !== this.$store.state.reference.selectedDB.id) {
          this.loading = 'error'
          this.error = 'Reference DB tidak sesuai'
        } else {
          const areaSummary = [
            ...data.areaItems.map(area => ({
              ...area,
              total_dft: area.items.map(el => el.dry_film_thickness).reduce((a, b) => a + b, 0),
              total_volume: Math.round(area.category.name.toUpperCase() !== 'FREE ITEM'
                ? area.items.map(el => el.liter).reduce((a, b) => a + b, 0) * 100
                : area.items
                  .filter(item => !data.areaItems
                    .filter(ar => ar.category.name.toUpperCase() !== 'FREE ITEM' && ar.items
                      .some(it => it.item_id === item.item_id)).length > 0)
                  .map(el => el.liter)
                  .reduce((a, b) => a + b, 0) * 100) / 100,
              dft_realization: null,
              items: area.items.sort((a, b) => a.item_type.id - b.item_type.id),
            })),
          ]

          this.quotationData = {
            ...data,
            total_volume: areaSummary.map(el => el.total_volume).reduce((a, b) => a + b, 0),
            total_dft: areaSummary.map(el => el.total_dft).reduce((a, b) => a + b, 0),
            areaItems: areaSummary,
          }

          if (data.dry_report_id) {
            await this.$store.dispatch('getDryReportDetail', data.dry_report_id).then(dryReport => {
              this.dryReport = dryReport

              dryReport.areas.forEach(area => {
                const index = this.quotationData.areaItems.findIndex(el => el.category.id === area.category.id)

                this.quotationData.areaItems[index].dft_realization = area.real_data
              })
            })
          }

          this.loading = false
        }
      }).catch(error => {
        this.loading = 'error'
        this.error = `${error.graphQLErrors[0].message}`
      })
    },
    formatDate(date, noSplit = false) {
      if (!date) {
        return ''
      }
      const parsedDate = Date.parse(noSplit ? date : date.split('.')[0])
      return moment(parsedDate).format('DD-MM-YYYY')
    },
    ...mapActions(['getQuoteDetail', 'getQuoteDetailSO', 'getPaymentTerm']),
    submitQuote() {
      this.$swal({
        title: 'Submit Quote?',
        text: 'Konfirmasi jika anda ingin submit quote',
        showCancelButton: true,
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batalkan',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'text-primary btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.loadingStatus = true
          this.$store.dispatch('submitQuote', { id: Number(this.id) }).then(async response => {
            if (response.data.submitQuote.status.toLowerCase() === 'success') {
              this.loadingStatus = false
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Berhasil disubmit',
                  icon: 'EditIcon',
                  variant: 'success',
                },
              })
              this.fetchDetail()
            } else {
              this.loadingStatus = false
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data.submitQuote.message,
                  icon: 'EditIcon',
                  variant: 'warning',
                },
              })
            }
          }).catch(() => {
            this.loadingStatus = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Terjadi kesalahan',
                icon: 'EditIcon',
                variant: 'warning',
              },
            })
          })
        }
      })
    },
    closeQuote() {
      this.$swal({
        title: 'Close Quote?',
        text: 'Konfirmasi jika anda ingin menclose quote',
        showCancelButton: true,
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batalkan',
        customClass: {
          confirmButton: 'btn btn-warning',
          cancelButton: 'text-warning btn btn-outline-warning ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.loadingStatus = true
          await this.$store.dispatch('closeQuote', { id: Number(this.id) }).then(async response => {
            if (response.data.closeQuote.status.toLowerCase() === 'success') {
              this.loadingStatus = false
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Berhasil diclose!',
                  icon: 'EditIcon',
                  variant: 'success',
                },
              })
              this.fetchDetail()
            } else {
              this.loadingStatus = false
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data.submitQuote.message,
                  icon: 'EditIcon',
                  variant: 'warning',
                },
              })
            }
          }).catch(() => {
            this.loadingStatus = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Terjadi kesalahan',
                icon: 'EditIcon',
                variant: 'warning',
              },
            })
          })
        }
      })
    },
    cancelQuote() {
      this.$swal({
        title: 'Cancel Quote?',
        text: 'Konfirmasi jika anda ingin mencancel quote',
        showCancelButton: true,
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batalkan',
        customClass: {
          confirmButton: 'btn btn-warning',
          cancelButton: 'text-warning btn btn-outline-warning ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.loadingStatus = true
          await this.$store.dispatch('closeQuote', { id: Number(this.id) }).then(async response => {
            if (response.data.closeQuote.status.toLowerCase() === 'success') {
              this.loadingStatus = false
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Berhasil dicancel!',
                  icon: 'EditIcon',
                  variant: 'success',
                },
              })
              this.fetchDetail()
            } else {
              this.loadingStatus = false
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data.submitQuote.message,
                  icon: 'EditIcon',
                  variant: 'warning',
                },
              })
            }
          }).catch(() => {
            this.loadingStatus = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Terjadi kesalahan',
                icon: 'EditIcon',
                variant: 'warning',
              },
            })
          })
        }
      })
    },
    acceptRejectQuote(status) {
      this.$swal({
        title: status === 0 ? 'Reject Quote?' : 'Approve Quote?',
        text: status === 0 ? 'Konfirmasi jika anda ingin mereject quote' : 'Konfirmasi jika anda ingin mengapprove quote',
        showCancelButton: true,
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batalkan',
        customClass: {
          confirmButton: status === 0 ? 'btn btn-danger' : 'btn btn-primary',
          cancelButton: status === 0 ? 'text-danger btn btn-outline-danger ml-1' : 'text-primary btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value && status === 0) {
          this.$swal({
            title: 'Alasan Reject',
            input: 'text',
            html: '<p>Beri alasan reject di bawah ini.</p',
            showCancelButton: true,
            confirmButtonText: 'Lanjutkan',
            cancelButtonText: 'Batalkan',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-warning ml-1',
            },
            buttonsStyling: false,
          }).then(async notes => {
            if (notes.isConfirmed) {
              this.loadingStatus = true
              await this.$store.dispatch('acceptRejectQuote', {
                id: Number(this.id),
                status,
                notes: notes.value,
              }).then(async response => {
                if (response.data.acceptRejectQuote.status.toLowerCase() === 'success') {
                  this.loadingStatus = false
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: status === 0 ? 'Berhasil direject!' : 'Berhasil diapprove!',
                      icon: 'EditIcon',
                      variant: 'success',
                    },
                  })
                  this.fetchDetail()
                } else {
                  this.loadingStatus = false
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: response.data.submitQuote.message,
                      icon: 'EditIcon',
                      variant: 'warning',
                    },
                  })
                }
              }).catch(() => {
                this.loadingStatus = false
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Terjadi kesalahan',
                    icon: 'EditIcon',
                    variant: 'warning',
                  },
                })
              })
            }
          })
        } else if (result.value && status === 1) {
          this.loadingStatus = true
          await this.$store.dispatch('acceptRejectQuote', {
            id: Number(this.id),
            status,
            notes: '',
          }).then(async response => {
            if (response.data.acceptRejectQuote.status.toLowerCase() === 'success') {
              this.loadingStatus = false
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: status === 0 ? 'Berhasil direject!' : 'Berhasil diapprove!',
                  icon: 'EditIcon',
                  variant: 'success',
                },
              })
              this.fetchDetail()
            } else {
              this.loadingStatus = false
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data.submitQuote.message,
                  icon: 'EditIcon',
                  variant: 'warning',
                },
              })
            }
          }).catch(() => {
            this.loadingStatus = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Terjadi kesalahan',
                icon: 'EditIcon',
                variant: 'warning',
              },
            })
          })
        }
      })
    },
    rejectByCustomer() {
      this.$swal({
        title: 'Reject Quote?',
        text: 'Konfirmasi jika anda ingin mereject quote',
        showCancelButton: true,
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batalkan',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'text-danger btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.$swal({
            title: 'Alasan Reject',
            input: 'text',
            html: '<p>Beri alasan reject di bawah ini.</p',
            showCancelButton: true,
            confirmButtonText: 'Lanjutkan',
            cancelButtonText: 'Batalkan',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-warning ml-1',
            },
            buttonsStyling: false,
          }).then(async notes => {
            if (notes.isConfirmed) {
              this.$store.dispatch('rejectQuoteByCustomer', { id: Number(this.id), notes: notes.value }).then(response => {
                if (response.data.rejectQuoteByCustomer.status.toLowerCase() === 'success') {
                  this.loadingStatus = false
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Berhasil direject!',
                      icon: 'EditIcon',
                      variant: 'success',
                    },
                  })
                  this.fetchDetail()
                } else {
                  this.loadingStatus = false
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: response.data.rejectQuoteByCustomer.message,
                      icon: 'EditIcon',
                      variant: 'warning',
                    },
                  })
                }
              }).catch(() => {
                this.loadingStatus = false
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Terjadi kesalahan',
                    icon: 'EditIcon',
                    variant: 'warning',
                  },
                })
              })
            }
          })
        }
      })
    },
    reviseQuote() {
      this.$router.push({
        name: 'revisequote',
        params: {
          id: this.id,
          version: this.version,
        },
      })
    },
    editQuote() {
      this.$router.push({
        name: 'editquote',
        params: {
          id: this.id,
          version: this.version,
        },
      })
    },
    compareVersion() {
      this.$refs.compareVersion.open()
    },
    async openSalesOrderForm() {
      this.$refs.salesOrder.open()
      if (!this.quoteDetailSO) {
        this.getQuoteDetailSO(Number(this.id)).then(data => {
          const DetailSO = data
          DetailSO.customerNo = this.quotationData.project.customer.customerNo
          DetailSO.transDate = new Date()
          DetailSO.cashDiscount = ''
          DetailSO.paymentTermName = this.quotationData.project.customer.term ? this.quotationData.project.customer.term : ''
          DetailSO.poNumber = ''
          DetailSO.shipDate = new Date()
          DetailSO.shipmentName = ''
          DetailSO.toAddress = this.quotationData.project.customer.billStreet
          DetailSO.notes = ''
          DetailSO.type = ''
          DetailSO.category = ''
          data.items.forEach((item, indexItem) => {
            let hasAgent1 = false
            let hasAgent2 = false

            if (item.agent_item) {
              hasAgent1 = true
              DetailSO.items[indexItem].agent_item.packagings.sort((a, b) => (a.liter > b.liter ? 1 : -1))
              item.agent_item.packagings.forEach((packaging, indexPackaging) => {
                DetailSO.items[indexItem].agent_item.packagings[indexPackaging].volume = 0
              })
            }
            if (item.agent_item_2) {
              hasAgent2 = true
              DetailSO.items[indexItem].agent_item_2.packagings.sort((a, b) => (a.liter > b.liter ? 1 : -1))
              item.agent_item_2.packagings.forEach((packaging, indexPackaging) => {
                DetailSO.items[indexItem].agent_item_2.packagings[indexPackaging].volume = 0
              })
            }
            const packagingsSorted = item.packagings.sort((a, b) => (a.liter > b.liter ? 1 : -1))
            packagingsSorted.forEach((packaging, indexPackaging) => {
              if (packaging.item_code === item.item_code) {
                DetailSO.items[indexItem].packagings[indexPackaging].liter_input = item.total_liter
                let { liter } = DetailSO.items[indexItem].packagings[indexPackaging]

                if (hasAgent1) {
                  const agentPackaging = item.agent_item.packagings.find(el => el.packaging_name === packaging.packaging_name)
                  liter += agentPackaging.liter
                }

                if (hasAgent2) {
                  const agentPackaging = item.agent_item_2.packagings.find(el => el.packaging_name === packaging.packaging_name)
                  liter += agentPackaging.liter
                }

                const qty = Math.round(item.total_liter / liter)
                DetailSO.items[indexItem].packagings[indexPackaging].qty = Number.isFinite(qty) ? qty : 0

                if (item.agent_item) {
                  const agentPackaging = item.agent_item.packagings.find(el => el.packaging_name === packaging.packaging_name)

                  if (agentPackaging) {
                    agentPackaging.volume = item.agent_item.item_liter
                  }
                }

                if (item.agent_item_2) {
                  const agentPackaging2 = item.agent_item_2.packagings.find(el => el.packaging_name === packaging.packaging_name)

                  if (agentPackaging2) {
                    agentPackaging2.volume = item.agent_item_2.item_liter
                  }
                }
              } else {
                DetailSO.items[indexItem].packagings[indexPackaging].liter_input = 0
                DetailSO.items[indexItem].packagings[indexPackaging].qty = 0
              }

              let uniteLiter = packaging.liter
              if (item.agent_item) {
                const agentPackaging = item.agent_item.packagings.find(el => el.packaging_name === packaging.packaging_name)

                uniteLiter += agentPackaging.liter
              }

              if (item.agent_item_2) {
                const agentPackaging2 = item.agent_item_2.packagings.find(el => el.packaging_name === packaging.packaging_name)

                uniteLiter += agentPackaging2.liter
              }

              DetailSO.items[indexItem].packagings[indexPackaging].uniteLiter = uniteLiter
            })
            DetailSO.items[indexItem].error = null
          })

          if (data.free_items.length) {
            data.free_items.forEach((item, indexItem) => {
              let hasAgent1 = false
              let hasAgent2 = false

              if (item.agent_item) {
                hasAgent1 = true
                DetailSO.free_items[indexItem].agent_item.packagings.sort((a, b) => (a.liter > b.liter ? 1 : -1))
                DetailSO.free_items[indexItem].agent_item.packagings.forEach((packaging, indexPackaging) => {
                  DetailSO.free_items[indexItem].agent_item.packagings[indexPackaging].volume = 0
                })
              }

              if (item.agent_item_2) {
                hasAgent2 = true
                DetailSO.free_items[indexItem].agent_item_2.packagings.sort((a, b) => (a.liter > b.liter ? 1 : -1))
                DetailSO.free_items[indexItem].agent_item_2.packagings.forEach((packaging, indexPackaging) => {
                  DetailSO.free_items[indexItem].agent_item_2.packagings[indexPackaging].volume = 0
                })
              }

              const packagingsSorted = item.packagings.sort((a, b) => (a.liter > b.liter ? 1 : -1))
              packagingsSorted.forEach((packaging, indexPackaging) => {
                if (indexPackaging === 0) {
                  DetailSO.free_items[indexItem].packagings[indexPackaging].liter_input = item.total_liter
                  let { liter } = packaging

                  if (hasAgent1) {
                    const agentPackaging = item.agent_item.packagings.find(el => el.packaging_name === packaging.packaging_name)
                    liter += agentPackaging.liter

                    if (agentPackaging) {
                      agentPackaging.volume = item.agent_item.item_liter
                    }
                  }

                  if (hasAgent2) {
                    const agentPackaging = item.agent_item_2.packagings.find(el => el.packaging_name === packaging.packaging_name)
                    liter += agentPackaging.liter

                    if (agentPackaging) {
                      agentPackaging.volume = item.agent_item_2.item_liter
                    }
                  }

                  const qty = Math.round(item.total_liter / liter)
                  DetailSO.free_items[indexItem].packagings[indexPackaging].qty = Number.isFinite(qty) ? qty : 0
                } else {
                  DetailSO.free_items[indexItem].packagings[indexPackaging].liter_input = 0
                  DetailSO.free_items[indexItem].packagings[indexPackaging].qty = 0
                }

                let uniteLiter = packaging.liter
                if (item.agent_item) {
                  const agentPackaging = item.agent_item.packagings.find(el => el.packaging_name === packaging.packaging_name)

                  uniteLiter += agentPackaging.liter
                }

                if (item.agent_item_2) {
                  const agentPackaging2 = item.agent_item_2.packagings.find(el => el.packaging_name === packaging.packaging_name)

                  uniteLiter += agentPackaging2.liter
                }

                DetailSO.free_items[indexItem].packagings[indexPackaging].uniteLiter = uniteLiter
              })
              DetailSO.free_items[indexItem].error = null
            })
          }

          this.quoteDetailSO = DetailSO
        })

        this.$store.dispatch('getShipments')
        this.$store.dispatch('getSalesOrderTypes')
        this.$store.dispatch('getSalesOrderCategories')
        this.paymentTermOptions = await this.getPaymentTerm()
      }
    },
    handleSpinInput(data, indexItem, indexPackaging) {
      console.log(data, indexItem, indexPackaging)
      console.log(this.quoteDetailSO.items[indexItem])
      const volAgent = this.quoteDetailSO.items[indexItem].agent_item?.item_liter + this.quoteDetailSO.items[indexItem].agent_item_2?.item_liter
      if (data > this.quoteDetailSO.items[indexItem].item_liter + (volAgent ?? 0)) {
        return
      }
      this.quoteDetailSO.items[indexItem].error = null
      let amount = data
      const nextindex = indexPackaging + 1 === this.quoteDetailSO.items[indexItem].packagings.length ? indexPackaging - 1 : indexPackaging + 1
      if (data > this.quoteDetailSO.items[indexItem].total_liter) {
        amount = this.quoteDetailSO.items[indexItem].total_liter
        this.quoteDetailSO.items[indexItem].packagings[indexPackaging].liter_input = amount

        if (this.quoteDetailSO.items[indexItem].packagings.length > 1) {
          this.quoteDetailSO.items[indexItem].packagings[nextindex].liter_input = 0
        }
      } else if (data < 0) {
        this.quoteDetailSO.items[indexItem].packagings[indexPackaging].qty = 0
        this.quoteDetailSO.items[indexItem].packagings[indexPackaging].liter_input = 0
      } else {
        this.quoteDetailSO.items[indexItem].packagings[indexPackaging].qty = this.countQty(amount, this.quoteDetailSO.items[indexItem].packagings[indexPackaging].uniteLiter)
        this.quoteDetailSO.items[indexItem].packagings[indexPackaging].liter_input = amount

        if (this.quoteDetailSO.items[indexItem].packagings[indexPackaging].liter_input <= this.quoteDetailSO.items[indexItem].total_liter && this.quoteDetailSO.items[indexItem].packagings.length > 1) {
          const nextliter = this.quoteDetailSO.items[indexItem].total_liter - amount

          this.quoteDetailSO.items[indexItem].packagings[nextindex].liter_input = nextliter
          this.quoteDetailSO.items[indexItem].packagings[nextindex].qty = this.countQty(nextliter, this.quoteDetailSO.items[indexItem].packagings[nextindex].uniteLiter)

          this.quoteDetailSO.items[indexItem].packagings.forEach((el, index) => {
            if (index !== indexPackaging && index !== nextindex) {
              this.quoteDetailSO.items[indexItem].packagings[index].liter_input = 0
            }
          })
        }
      }
      this.recalculateAgent(indexItem, false)
    },
    handleFreeSpinInput(data, indexItem, indexPackaging) {
      if (data > this.quoteDetailSO.free_items[indexItem].item_liter) {
        return
      }
      this.quoteDetailSO.free_items[indexItem].error = null
      let amount = data
      const nextindex = indexPackaging + 1 === this.quoteDetailSO.free_items[indexItem].packagings.length ? indexPackaging - 1 : indexPackaging + 1
      if (data > this.quoteDetailSO.free_items[indexItem].total_liter) {
        amount = this.quoteDetailSO.free_items[indexItem].total_liter
        this.quoteDetailSO.free_items[indexItem].packagings[indexPackaging].liter_input = amount

        if (this.quoteDetailSO.free_items[indexItem].packagings.length > 1) {
          this.quoteDetailSO.free_items[indexItem].packagings[nextindex].liter_input = 0
        }
      } else if (data < 0) {
        this.quoteDetailSO.free_items[indexItem].packagings[indexPackaging].qty = 0
        this.quoteDetailSO.free_items[indexItem].packagings[indexPackaging].liter_input = 0
      } else {
        this.quoteDetailSO.free_items[indexItem].packagings[indexPackaging].qty = this.countQty(amount, this.quoteDetailSO.free_items[indexItem].packagings[indexPackaging].uniteLiter)
        this.quoteDetailSO.free_items[indexItem].packagings[indexPackaging].liter_input = amount

        if (this.quoteDetailSO.free_items[indexItem].packagings[indexPackaging].liter_input <= this.quoteDetailSO.free_items[indexItem].total_liter && this.quoteDetailSO.free_items[indexItem].packagings.length > 1) {
          const nextliter = this.quoteDetailSO.free_items[indexItem].total_liter - amount

          this.quoteDetailSO.free_items[indexItem].packagings[nextindex].liter_input = nextliter
          this.quoteDetailSO.free_items[indexItem].packagings[nextindex].qty = this.countQty(nextliter, this.quoteDetailSO.free_items[indexItem].packagings[nextindex].uniteLiter)

          this.quoteDetailSO.free_items[indexItem].packagings.forEach((el, index) => {
            if (index !== indexPackaging && index !== nextindex) {
              this.quoteDetailSO.free_items[indexItem].packagings[index].liter_input = 0
            }
          })
        }
      }
      this.recalculateAgent(indexItem, true)
    },
    recalculateAgent(indexItem, isFree = false) {
      const ref = isFree ? this.quoteDetailSO.free_items[indexItem] : this.quoteDetailSO.items[indexItem]

      if (ref.agent_item && ref.agent_item_2) {
        ref.packagings.forEach(packaging => {
          const agentItem1 = ref.agent_item.packagings.find(el => el.packaging_name === packaging.packaging_name)
          const agentItem2 = ref.agent_item_2.packagings.find(el => el.packaging_name === packaging.packaging_name)
          if (packaging.liter_input) {
            const multiplier = agentItem1.liter + agentItem2.liter + packaging.liter
            const volumeAgent1 = +packaging.liter_input * (agentItem1.liter / multiplier)
            const volumeAgent2 = +packaging.liter_input * (agentItem2.liter / multiplier)
            agentItem1.volume = Math.round(volumeAgent1 * 100) / 100
            agentItem2.volume = Math.round(volumeAgent2 * 100) / 100
          } else {
            agentItem1.volume = 0
            agentItem2.volume = 0
          }
        })
      } else if (ref.agent_item && !ref.agent_item_2) {
        ref.packagings.forEach(packaging => {
          const agentItem1 = ref.agent_item.packagings.find(el => el.packaging_name === packaging.packaging_name)
          if (packaging.liter_input) {
            const multiplier = agentItem1.liter + packaging.liter
            const volumeAgent = +packaging.liter_input * (agentItem1.liter / multiplier)
            agentItem1.volume = Math.round(volumeAgent * 100) / 100
          } else {
            agentItem1.volume = 0
          }
        })
      }
    },
    submitForwardSO() {
      this.$refs.validateForward.validate().then(success => {
        if (success) {
          const itemList = []
          let errorStatus = false

          this.loadingForward = true
          this.quoteDetailSO.items.forEach((item, indexItem) => {
            const totalInputLiter = item.packagings.reduce((a, b) => Number(a) + Number(b.liter_input), 0)

            if (item.total_liter !== totalInputLiter) {
              this.quoteDetailSO.items[indexItem].error = 'Input total liter harus sama dengan jumlah liter di quote'
              errorStatus = true
              this.loadingForward = false
            } else {
              item.packagings.forEach(packaging => {
                if (packaging.liter_input > 0) {
                  if (packaging.liter_input % packaging.uniteLiter !== 0) {
                    this.quoteDetailSO.items[indexItem].error = 'Jumlah liter harus sesuai dengan kelipatan kemasan liter'
                    errorStatus = true
                    this.loadingForward = false
                  } else {
                    let volumeItem = packaging.liter_input

                    if (item.agent_item && item.agent_item_2) {
                      const agentItem1 = item.agent_item.packagings.find(el => el.packaging_name === packaging.packaging_name)
                      const agentItem2 = item.agent_item_2.packagings.find(el => el.packaging_name === packaging.packaging_name)
                      const multiplier = packaging.liter + agentItem1.liter + agentItem2.liter
                      volumeItem = +packaging.liter_input * (packaging.liter / multiplier)
                    } else if (item.agent_item && !item.agent_item_2) {
                      const agentItem1 = item.agent_item.packagings.find(el => el.packaging_name === packaging.packaging_name)
                      const multiplier = packaging.liter + agentItem1.liter
                      volumeItem = +packaging.liter_input * (packaging.liter / multiplier)
                    }

                    itemList.push({
                      item_code: packaging.item_code,
                      liter: volumeItem,
                      price: item.price,
                      is_free: false,
                    })

                    if (item.agent_item) {
                      item.agent_item.packagings.forEach(pack => {
                        if (pack.volume > 0 && packaging.packaging_name === pack.packaging_name) {
                          itemList.push({
                            item_code: pack.item_code,
                            liter: pack.volume,
                            price: item.price,
                            is_free: false,
                          })
                        }
                      })
                    }

                    if (item.agent_item_2) {
                      item.agent_item_2.packagings.forEach(pack => {
                        if (pack.volume > 0 && packaging.packaging_name === pack.packaging_name) {
                          itemList.push({
                            item_code: pack.item_code,
                            liter: pack.volume,
                            price: item.price,
                            is_free: false,
                          })
                        }
                      })
                    }
                  }
                }
              })
            }
          })

          if (this.quoteDetailSO.free_items.length) {
            this.quoteDetailSO.free_items.forEach((item, indexItem) => {
              const totalInputLiter = item.packagings.reduce((a, b) => Number(a) + Number(b.liter_input), 0)

              if (item.total_liter !== totalInputLiter) {
                this.quoteDetailSO.free_items[indexItem].error = 'Input total liter harus sama dengan jumlah liter di quote'
                errorStatus = true
                this.loadingForward = false
              } else {
                item.packagings.forEach(packaging => {
                  if (packaging.liter_input > 0) {
                    if (packaging.liter_input % packaging.uniteLiter !== 0) {
                      this.quoteDetailSO.items[indexItem].error = 'Jumlah liter harus sesuai dengan kelipatan kemasan liter'
                      errorStatus = true
                      this.loadingForward = false
                    } else {
                      let volumeItem = packaging.liter_input

                      if (item.agent_item && item.agent_item_2) {
                        const agentItem1 = item.agent_item.packagings.find(el => el.packaging_name === packaging.packaging_name)
                        const agentItem2 = item.agent_item_2.packagings.find(el => el.packaging_name === packaging.packaging_name)
                        const multiplier = packaging.liter + agentItem1.liter + agentItem2.liter
                        volumeItem = +packaging.liter_input * (packaging.liter / multiplier)
                      } else if (item.agent_item && !item.agent_item_2) {
                        const agentItem1 = item.agent_item.packagings.find(el => el.packaging_name === packaging.packaging_name)
                        const multiplier = packaging.liter + agentItem1.liter
                        volumeItem = +packaging.liter_input * (packaging.liter / multiplier)
                      }

                      itemList.push({
                        item_code: packaging.item_code,
                        liter: volumeItem,
                        price: item.price,
                        is_free: true,
                      })

                      if (item.agent_item) {
                        item.agent_item.packagings.forEach(pack => {
                          if (pack.volume > 0 && packaging.packaging_name === pack.packaging_name) {
                            itemList.push({
                              item_code: pack.item_code,
                              liter: pack.volume,
                              price: item.price,
                              is_free: true,
                            })
                          }
                        })
                      }

                      if (item.agent_item_2) {
                        item.agent_item_2.packagings.forEach(pack => {
                          if (pack.volume > 0 && packaging.packaging_name === pack.packaging_name) {
                            itemList.push({
                              item_code: pack.item_code,
                              liter: pack.volume,
                              price: item.price,
                              is_free: true,
                            })
                          }
                        })
                      }
                    }
                  }
                })
              }
            })
          }

          if (!errorStatus) {
            this.$store.dispatch('forwardQuote', {
              quote_id: +this.id,
              customerNo: this.quoteDetailSO.customerNo,
              transDate: this.quoteDetailSO.transDate ? new Date(this.quoteDetailSO.transDate).toISOString().slice(0, 10) : null,
              description: this.quoteDetailSO.notes,
              cashDiscount: this.quotationData.discount,
              paymentTermName: this.quoteDetailSO.paymentTermName.name,
              poNumber: this.quoteDetailSO.poNumber,
              shipDate: this.quoteDetailSO.shipDate ? new Date(this.quoteDetailSO.shipDate).toISOString().slice(0, 10) : null,
              toAddress: this.quoteDetailSO.toAddress,
              items: itemList,
              type: this.quoteDetailSO.type,
              category: this.quoteDetailSO.category,
              shipmentName: this.quoteDetailSO.shipmentName ? this.quoteDetailSO.shipmentName.name : null,
            }).then(() => {
              this.loadingForward = false
              this.$refs.salesOrder.close()
              this.quoteDetailSO = ''
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Berhasil diforward',
                  icon: 'EditIcon',
                  variant: 'success',
                },
              })
              this.fetchDetail()
              this.$refs.updateProjectReminder.show(this.quotationData.project.id, this.quotationData.project)
            }).catch(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Terjadi kesalahan',
                  icon: 'EditIcon',
                  variant: 'danger',
                },
              })
              this.loadingForward = false
            })
          }
        }
      })
    },
    validateForward() {
      this.$refs.validateForward.validate().then(success => {
        if (success) {
          if (this.isInAccurate) {
            this.$refs.salesOrder.close()
            this.$bvModal.show('confirmForward')
          } else {
            this.$refs.salesOrder.close()
            this.addCustomerToAccurate()
          }
        }
      })
    },
    addCustomerToAccurate() {
      this.$swal({
        title: 'Customer ini belum tersedia di Accurate',
        text: 'Konfirmasi jika anda ingin menambah customer ke Accurate',
        showCancelButton: true,
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batalkan',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: ' text-danger btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('addCustomerAccurate', this.quotationData.project.customer.id).then(data => {
            if (data.data.addCustomerAccurate.status.toLowerCase() === 'success') {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Berhasil menambah customer ke Accurate!',
                  icon: 'EditIcon',
                  variant: 'success',
                },
              })
              this.validateForward()
            }
          })
        }
      })
    },
    countQty(liter, literPackaging) {
      if (liter < 0) {
        return 0
      }

      const qty = Math.round((liter / literPackaging) * 100) / 100
      return Number.isFinite(qty) ? qty : 0
    },
    resolveUserPermission() {
      return (this.$root.role === 2 || this.quotationData.userCreate.id === this.$store.getters.getCurrentUser.user.id)
    },
    getSalesOrderStatusVariant(status) {
      const filter = this.salesOrderStatusOptions.filter(el => el.label === status)

      if (!filter.length) {
        return 'secondary'
      }

      return filter[0].variant
    },
    captionSetLiter,
    findMainLiter(item, packaging) {
      if (item.agent_item && item.agent_item_2) {
        const agentItem1 = item.agent_item.packagings.find(el => el.packaging_name === packaging.packaging_name)
        const agentItem2 = item.agent_item_2.packagings.find(el => el.packaging_name === packaging.packaging_name)
        const multiplier = agentItem1.liter + agentItem2.liter + packaging.liter
        const volume = +packaging.liter_input * (packaging.liter / multiplier)

        return `${Math.round(volume * 100) / 100}L`
      }

      if (item.agent_item && !item.agent_item_2) {
        const agentItem1 = item.agent_item.packagings.find(el => el.packaging_name === packaging.packaging_name)
        const multiplier = agentItem1.liter + packaging.liter
        const volume = +packaging.liter_input * (packaging.liter / multiplier)

        return `${Math.round(volume * 100) / 100}L`
      }

      return `${packaging.liter_input}L`
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.logs-list {
  overflow: auto;
}

@media(max-width: 1200px) {
  .logs-list {
    overflow: hidden;
    height: auto;
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '~@core/scss/base/components/variables-dark';

.bottom-sheet {
  margin: 16px;
}

.custom-shadow {
  box-shadow: rgba(177, 177, 177, 0.1) 0px 8px 20px !important;
}

.form-item-section {
background-color:$product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: .35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }
}

.salesorder-card {
  border-radius: 6px;
  border: 1px solid rgb(223, 223, 223);
}

.horizontal-scroll {
  white-space: pre;
  overflow-x: auto;
}

</style>

<style>
.popover {
  z-index: 999999 !important;
}
</style>
