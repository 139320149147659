<template>
  <app-timeline>
    <XyzTransitionGroup
      appear
      class="item-group"
      xyz="fade stagger-1 right-3 duration-6 delay-3"
    >
      <app-timeline-item
        v-for="log in logs.slice().reverse()"
        :key="log.created_at"
        :title="statusOptions[log.status.id].label"
        :subtitle="log.notes"
        :time="formatDate(log.created_at)"
        :variant="statusOptions[log.status.id].variant"
      >
        <template #user>
          <div
            v-if="log.user"
            class="d-flex mt-50 mb-1"
          >
            <b-avatar
              :text="avatarText(log.user.name)"
              variant="light-info"
              class="mr-50"
              size="36"
              :src="log.user.photo ? log.user.photo : null"
            />
            <div>
              <router-link :to="{ name: 'user-detail', params: { id: log.user.id.toString() } }">
                <span class="font-weight-bold d-block text-primary">{{ log.user.name }}</span>
              </router-link>
              <small class="d-block text-muted">{{ log.user.role.name }}</small>
            </div>
          </div>
        </template>
      </app-timeline-item>
    </XyzTransitionGroup>
  </app-timeline>
</template>

<script>
import { BAvatar } from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import { mapGetters } from 'vuex'
import moment from 'moment'
import { avatarText } from '@core/utils/filter'

export default {
  components: {
    BAvatar,
    AppTimeline,
    AppTimelineItem,
  },
  props: {
    logs: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      statusOptions: 'getStatusOptions',
    }),
  },
  methods: {
    avatarText,
    formatDate(date) {
      if (!date) {
        return ''
      }
      const parsedDate = Date.parse(date.split('.')[0])
      return moment(parsedDate).locale('id').format('dddd, DD MMMM YYYY • H:mm')
    },
  },
}
</script>
