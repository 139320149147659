<template>
  <div>
    <b-card title="Autentikasi Email">
      <h6 class="font-weight-normal m-0">
        Hubungkan akun Google untuk mengirim dan menerima email quotation melalui Gmail.
      </h6>
      <XyzTransition
        appear
        xyz="fade left-3 duration-3"
      >
        <h6
          v-if="isSignedIn && currentUser"
          class="font-weight-normal m-0 mt-1"
        >
          Terhubung dengan <span class="font-weight-bold">{{ currentUser }}</span>
        </h6>
      </XyzTransition>
      <b-button
        v-if="!isSignedIn && isInit"
        v-ripple.400="'rgba(255, 255, 255, 0.3)'"
        class="mt-1 mr-1 w-100"
        variant="light"
        @click="loadingSignIn ? null : signIn()"
      >
        <b-spinner
          v-if="loadingSignIn"
          small
        />
        <span v-else>
          <b-img
            height="18"
            :src="require('@/assets/images/logo/google.png')"
            class="mr-50"
          />
          Masuk Ke Akun Google
        </span>
      </b-button>
      <b-button
        v-else-if="isSignedIn && isInit"
        v-ripple.400="'rgba(0, 0, 0, 0.1)'"
        class="mt-1 w-100"
        variant="outline-danger"
        @click="signOut"
      >
        Sign Out Akun Google
      </b-button>
      <b-button
        v-if="isSignedIn && !authCode && isInit"
        ref="btnConnect"
        v-ripple.400="'rgba(255, 255, 255, 0.3)'"
        class="mt-1 mr-1 w-100"
        variant="light"
        @click="loadingSignIn ? null : $store.dispatch('getAuthCode')"
      >
        <b-spinner
          v-if="loadingSignIn"
          small
        />
        <span v-else>
          <b-img
            height="18"
            :src="require('@/assets/images/logo/logo-icon.png')"
            class="mr-50"
          />
          Hubungkan Ke Quotation System
        </span>
      </b-button>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BButton, BImg, BSpinner,
} from 'bootstrap-vue'
import { createHelpers } from 'vuex-map-fields'

const { mapFields } = createHelpers({
  getterType: 'getGoogleField',
  mutationType: 'updateGoogleField',
})

export default {
  components: {
    BImg,
    BCard,
    BButton,
    BSpinner,
  },
  computed: {
    ...mapFields([
      'currentUser',
      'isSignedIn',
      'loadingSignIn',
      'isInit',
      'authCode',
    ]),
  },
  mounted() {
    this.$store.dispatch('checkToken')
    const that = this
    const checkGauthLoad = setInterval(() => {
      that.isInit = that.$gAuth.isInit
      if (that.isInit) {
        this.isInit = true
        clearInterval(checkGauthLoad)
      }
    }, 1000)
  },
  methods: {
    async signIn() {
      await this.$store.dispatch('signIn')
    },
    signOut() {
      this.$swal({
        title: 'Sign out?',
        text: 'Konfirmasi jika anda ingin sign out.',
        showCancelButton: true,
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batalkan',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: ' text-danger btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('signOut')
        }
      })
    },
  },
}
</script>

<style>

</style>
