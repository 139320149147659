<template>
  <div
    id="sticky"
    ref="sticky"
    class="stickypanel fixed"
  >
    <slot />
  </div>
</template>

<script>
export default {

}
</script>

<style>
  @media(min-width: 1200px){
    .fixed {
      position: fixed;
      width: 24%;
      transition: opacity 500ms linear;
    }
  }
</style>
