<template>
  <div>
    <b-modal
      id="emailForm"
      scrollable
      title="Kirim Email Customer"
      size="lg"
      :hide-footer="loading"
      hide-header-close
      no-close-on-esc
      no-close-on-backdrop
      @hidden="resetForm()"
    >
      <div
        v-show="!isSignedIn || !authCode"
      >
        <auth-page ref="auth" />
      </div>
      <div
        v-if="isSignedIn && authCode"
      >
        <validation-observer ref="validateForm">
          <b-alert
            :show="currentUser !== ''"
            variant="primary"
          >
            <div class="alert-body d-flex">
              <h6
                class="font-weight-normal m-0"
              >
                Terhubung dengan <span class="font-weight-bold">{{ currentUser }}.</span>
              </h6>
              <h6
                class="m-0 ml-auto cursor-pointer text-danger"
                @click="$refs.auth.signOut()"
              >
                Sign out
              </h6>
            </div>
          </b-alert>

          <b-form-radio-group
            v-model="formData.type"
            class="list item-view-radio-group w-100 mt-1 d-flex flex-wrap"
            size="md"
            buttons
            button-variant="outline-primary"
          >
            <b-form-radio
              v-b-popover.hover.bottom="'Quote PDF'"
              value="1"
            >
              Tipe 1
            </b-form-radio>
            <b-form-radio
              v-b-popover.hover.bottom="'Quote PDF (Tanpa Harga)'"
              value="2"
            >
              Tipe 2
            </b-form-radio>
            <b-form-radio
              v-b-popover.hover.bottom="'Price List PDF'"
              value="3"
            >
              Tipe 3
            </b-form-radio>
            <b-form-radio
              v-b-popover.hover.bottom="'PDF Per M2'"
              value="4"
            >
              Tipe 4
            </b-form-radio>
          </b-form-radio-group>

          <div class="d-flex align-items-center justify-content-between mt-2">
            <span>
              Tampilkan Loss Factor
            </span>
            <b-form-checkbox
              v-model="showLossFactor"
              switch
            />
          </div>

          <b-form-group
            class="mt-1"
          >
            <div class="d-flex align-items-center justify-content-between mb-50">
              <label class="bv-no-focus-ring col-form-label">Email Tujuan</label>
              <b-button
                :variant="withCc ? 'primary' : 'outline-primary'"
                size="sm"
                @click="withCc = !withCc"
              >
                CC & BCC
              </b-button>
            </div>
            <b-form-tags
              v-model="formData.to"
              add-button-text="Tambah"
              placeholder="Tambah email tujuan"
              duplicate-tag-text="Email sudah diisi"
              invalid-tag-text="Bukan email yang valid"
              remove-on-delete
              :tag-validator="validateEmail"
            />

            <template #description>
              <div id="tags-validation-help">
                Tambah satu atau lebih email tujuan. Tekan enter atau klik tambah untuk menambah email tujuan.
              </div>
            </template>
          </b-form-group>

          <b-collapse :visible="withCc">
            <b-form-group
              label="CC"
            >
              <b-form-tags
                v-model="formData.cc"
                add-button-text="Tambah"
                placeholder="Tambah email tujuan"
                duplicate-tag-text="Email sudah diisi"
                invalid-tag-text="Bukan email yang valid"
                remove-on-delete
                :tag-validator="validateEmail"
              />
            </b-form-group>

            <b-form-group
              class="mt-1"
              label="BCC"
            >
              <b-form-tags
                v-model="formData.bcc"
                add-button-text="Tambah"
                placeholder="Tambah email tujuan"
                duplicate-tag-text="Email sudah diisi"
                invalid-tag-text="Bukan email yang valid"
                remove-on-delete
                :tag-validator="validateEmail"
              />
            </b-form-group>
          </b-collapse>

          <b-form-group
            class="mt-1"
            label="Subjek"
          >
            <validation-provider
              #default="{ errors }"
              name="Subjek"
              rules="required|max:128|min:0"
            >
              <b-form-input
                v-model="formData.subject"
                placeholder="Subjek atau judul email"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            class="mt-1 mb-0"
            label="Attachment"
          >
            <b-row
              class="px-50"
            >
              <b-col
                v-for="(file, index) in attachment"
                :key="index"
                cols="12"
                lg="6"
                class="px-50"
              >
                <div
                  class="custom-attachment d-flex align-items-center p-1 mb-1 cursor-pointer"
                  :class="{ 'checked': attachment[index].checked }"
                  @click="attachment[index].checked = !attachment[index].checked"
                >
                  <div>
                    <label
                      class="font-weight-bold m-0 d-block cursor-pointer"
                    >{{ file.name }}</label>
                    <small
                      v-b-popover.hover.top="file.fromItems.length > 1 ? file.fromItems.map(el => el.name).join(', ') : ''"
                      class="text-muted"
                    >
                      {{ file.fromItems.length > 1 ? 'Dari Berbagai Item' : file.fromItems[0].name }}
                    </small>
                  </div>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    class="ml-auto"
                    size="sm"
                    variant="outline-danger"
                    @click.stop="$refs.pdf.show(file.url)"
                  >
                    <b-img
                      :src="require('@/assets/images/icons/pdf.png')"
                      height="auto"
                      width="16"
                    />
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </b-form-group>

          <label class="mb-50 bv-no-focus-ring col-form-label">Preview Email</label>
          <!-- eslint-disable vue/no-v-html -->
          <div
            v-if="previewMail"
            v-html="previewMail"
          />
          <!-- eslint-enable vue/no-v-html -->
          <b-skeleton
            v-else
            width="100%"
            height="50vh"
          />
        </validation-observer>
      </div>

      <template
        #modal-footer
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="secondary"
          @click="closeForm()"
        >
          Batalkan
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="loadingSubmit ? null : submitForm()"
        >
          <span v-if="!loadingSubmit">Kirim</span>
          <b-spinner
            v-else
            small
          />
        </b-button>
      </template>
    </b-modal>

    <pdf-preview
      ref="pdf"
    />
  </div>
</template>

<script>
import {
  BModal, BAlert, BFormRadioGroup, BFormRadio, BButton, BSpinner, BFormGroup, BFormCheckbox,
  BFormTags, BFormInput, BImg, BRow, BCol, BCollapse, VBPopover, BSkeleton,
} from 'bootstrap-vue'
import AuthPage from '@/views/profile/components/AuthPage.vue'
import { createHelpers } from 'vuex-map-fields'
import PdfPreview from '@/components/misc/PdfPreview.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const { mapFields } = createHelpers({
  getterType: 'getGoogleField',
  mutationType: 'updateGoogleField',
})

export default {
  components: {
    BModal,
    BAlert,
    BFormRadioGroup,
    BFormRadio,
    BButton,
    BSpinner,
    BFormGroup,
    BFormTags,
    BFormInput,
    BImg,
    BRow,
    BCol,
    BCollapse,
    BSkeleton,
    BFormCheckbox,
    PdfPreview,
    AuthPage,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-popover': VBPopover,
  },
  data() {
    return {
      quoteData: '',
      loading: false,
      loadingSubmit: false,
      withCc: false,
      previewMail: '',
      attachment: [],
      formData: {
        type: '1',
        to: [],
        cc: [],
        bcc: [],
        subject: '',
      },
      showLossFactor: false,
    }
  },
  computed: {
    ...mapFields([
      'currentUser',
      'isSignedIn',
      'authCode',
      'loadingSignIn',
    ]),
  },
  methods: {
    async show(quoteDetail) {
      this.quoteData = quoteDetail
      this.formData.subject = `Quote Project ${quoteDetail.project.name} Untuk ${quoteDetail.project.customer.name}`

      if (quoteDetail.project.customer.email) {
        this.formData.to = [quoteDetail.project.customer.email]
      }

      this.$store.dispatch('previewSendEmailCustomer', quoteDetail.id).then(result => {
        this.previewMail = result
      })

      this.$bvModal.show('emailForm')

      this.quoteData.areaItems.forEach(area => {
        area.items.forEach(item => {
          if (item.data_sheet) {
            const fileName = item.data_sheet.substring(item.data_sheet.lastIndexOf('/') + 1)

            if (this.attachment.some(el => el.name === fileName)) {
              const index = this.attachment.findIndex(el => el.name === fileName)

              if (!this.attachment[index].fromItems.some(el => el.id === item.item_id)) {
                this.attachment[index].fromItems.push({
                  name: `${item.item_name} (${item.item_code})`,
                  id: item.item_id,
                })
              }
            } else {
              this.attachment.push({
                name: fileName,
                fromItems: [{
                  name: `${item.item_name} (${item.item_code})`,
                  id: item.item_id,
                }],
                url: item.data_sheet,
                checked: true,
              })
            }
          }
        })
      })
    },
    closeForm() {
      this.$swal({
        title: 'Tutup form?',
        text: 'Progress form anda belum disimpan, ingin menutup form?',
        showCancelButton: true,
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batalkan',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: ' text-danger btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$bvModal.hide('emailForm')
        }
      })
    },
    submitForm() {
      this.$refs.validateForm.validate().then(success => {
        if (success) {
          if (this.formData.to.length) {
            this.$swal({
              title: 'Cek Kembali Data Anda',
              text: 'Data akan dikirimkan ke penerima terkait, harap cek kembali bahwa data sudah benar.',
              showCancelButton: true,
              confirmButtonText: 'Yakin',
              cancelButtonText: 'Batalkan',
              customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: ' text-primary btn btn-outline-primary ml-1',
              },
              buttonsStyling: false,
            }).then(result => {
              if (result.value) {
                this.loadingSubmit = true
                const attachmentList = this.attachment.filter(el => el.checked).map(el => el.fromItems[0].id)
                this.$store.dispatch('sendEmailCustomer', {
                  quote_id: this.quoteData.id,
                  type: +this.formData.type,
                  to: this.formData.to,
                  cc: this.formData.cc.length ? this.formData.cc : null,
                  bcc: this.formData.bcc.length ? this.formData.bcc : null,
                  subject: this.formData.subject,
                  item_id: attachmentList.length ? attachmentList : null,
                  isLossFactorVisible: this.showLossFactor,
                })

                this.loadingSubmit = false
                this.$bvModal.hide('emailForm')
              }
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Harap isi email tujuan!',
                icon: 'SendIcon',
                variant: 'danger',
              },
            })
          }
        }
      })
    },
    resetForm() {
      this.quoteData = ''
      this.loading = false
      this.loadingSubmit = false
      this.withCc = false
      this.previewMail = ''
      this.attachment = []
      this.formData = {
        type: '1',
        to: [],
        cc: [],
        bcc: [],
        subject: '',
      }
    },
    validateEmail(tag) {
      const re = /^\S+@\S+\.\S+$/
      return re.test(tag)
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/core/colors/palette-variables.scss';

.custom-attachment {
  border-radius: 8px;
  border: 2px solid rgb(223, 223, 223);
  transition: all 0.3s ease;
}

.checked {
  border: 2px solid lighten($color: $primary, $amount: 10%) !important;
}
</style>
