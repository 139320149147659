<template>
  <b-input-group
    :class="{ 'is-invalid' : error }"
  >
    <b-input-group-prepend
      class="cursor-pointer"
      is-text
      @click="minusItem"
    >
      <feather-icon
        icon="MinusIcon"
        size="16"
        class="text-secondary"
      />
    </b-input-group-prepend>
    <b-form-input
      :key="keyInput"
      v-model="spindata"
      type="number"
      placeholder="Input Angka"
      class="text-center"
      v-bind="$attrs"
      :state="error ? !error : null"
    />
    <b-input-group-append
      class="cursor-pointer"
      is-text
      @click="plusItem"
    >
      <feather-icon
        icon="PlusIcon"
        size="16"
        class="text-secondary"
      />
    </b-input-group-append>
  </b-input-group>
</template>

<script>
import {
  BInputGroup, BInputGroupPrepend, BInputGroupAppend, BFormInput,
} from 'bootstrap-vue'

export default {
  components: {
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormInput,
  },
  props: {
    value: {
      type: Number,
      default: 0,
    },
    increment: {
      type: Number,
      default: 1,
    },
    max: {
      type: Number,
      required: false,
      default: null,
    },
    error: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      keyInput: '',
    }
  },
  computed: {
    spindata: {
      get() {
        return this.value
      },
      set(val) {
        if (this.max && val <= this.max) {
          this.onInput(val)
        } else {
          this.onInput(this.max)
        }

        if (!this.max) {
          this.onInput(val)
        }
        // re render input
        setTimeout(() => {
          if (val > this.value) {
            this.keyInput = Math.random()
          }
        }, 500)
      },
    },
  },
  methods: {
    onInput(value) {
      this.$emit('input', Number(value))
    },
    minusItem() {
      const oldvalue = Number(this.value)
      const newvalue = oldvalue - this.increment

      if (newvalue > 0) {
        this.onInput(newvalue)
      } else {
        this.onInput(0)
      }
    },
    plusItem() {
      const oldvalue = Number(this.value)
      const newvalue = oldvalue + this.increment

      if (this.max && newvalue <= this.max) {
        this.onInput(newvalue)
      }

      if (!this.max) {
        this.onInput(newvalue)
      }
    },
  },
}
</script>

<style>

</style>
